export const getStatusName = (value) => {
  // Convert value to a number if it's a string
  const numericValue = parseInt(value, 10);

  // Use a switch case to determine the label based on the value
  switch (numericValue) {
    case 0:
      return "Beauftragt";
    case 1:
      return "Weiterleitung";
    case 2:
      return "Widerspruch";
    case 3:
      return "Gescheitert";
    case 4:
      return "Gelöscht";
    case 5:
      return "Neu";
    default:
      return "Unknown";
  }
};
