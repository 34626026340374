/* eslint-disable no-useless-computed-key */
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getHeader } from "../shared/Authorization";
import { ErrorHandler, storageLoginLanguageSet } from "../shared/CustomFunc";
// import axios from "axios";
// import { cleanAuthorization, saveAuthorization } from "../shared/Authorization";

// const rootUrl = process.env.REACT_APP_API_URL

const ROOT_URL = process.env.REACT_APP_API_URL

export const userSlice = createSlice( {
    name: 'user',
    initialState: {
        value: { }
    },
    reducers: {
        loadUser: ( state, action ) => {
            state.value = action.payload
        },
        updateUser: ( state, action ) => {
            state.value = {
                ...state.value,
                ...action.payload
            }
        },
        cleanUser: ( state ) => {
            state.value = {}
        }
    }
} )

// "Error fetching user"
// "Fehler beim Abrufen des Benutzers"
export const fetchUser = () => async dispatch => {
    const headers = getHeader( )

    return await axios.get( ROOT_URL + "/user", { headers } )
    .then( resp => {
        const _applications = resp.data?.client_info?.upgrades || []
        const foundApplication = _applications.find( upgrade => upgrade.status === 1 || upgrade.status === 2  )
        
        dispatch( loadUser( 
            { 
                ...resp.data.client_info,
                phone: resp.data?.seller_info?.phone || "",
                contact_url: resp.data?.seller_info?.contact_url  || "",
                hasApplied: foundApplication?.id ? true : false
            } ) )
        return { ok: true }
    } )
    .catch( err => {
        return ErrorHandler( err, toast, "Error fetching user" )
    } )
}

// success
// "Update profile successfully"
// "Profil erfolgreich aktualisieren"

// error
// "Error updating user"
// "Fehler beim Aktualisieren des Benutzers"
export const updatePersonalInformation = ( data ) => async () => {
    const headers = getHeader( { 'Content-Type': 'multipart/form-data' } )

    data._method = "PUT"

    return axios.post( ROOT_URL + "/setting/update-profile", data, { headers } )
    .then( () => {
        if ( "language" in data ) {
            storageLoginLanguageSet( data.language )
        }
        return { ok: true, message: "Update profile successfully" }
    } )
    .catch( err => {
        return ErrorHandler( err, toast, "Error updating user" )
    } )
}


// success
// "Added business successfully"
// "Geschäft erfolgreich hinzugefügt"

// error
// "Error adding business"
// "Fehler beim Hinzufügen des Unternehmens"

export const addCompany = ( data ) => async () => {
    const headers = getHeader({ 'Content-Type': 'multipart/form-data' } )

    return axios.post( ROOT_URL + "/setting/store-business", data, { headers } )
    .then( () => {
        return { ok: true, message: "Added business successfully" }
    } )
    .catch( err => {
        return ErrorHandler( err, toast, "Error adding business" )
    } )
}

export const updateCompany = ( id, data ) => async () => {
    const headers = getHeader( { 'Content-Type': 'application/x-www-form-urlencoded' } )

    return axios.put( ROOT_URL + '/setting/update-company/' + id, data, { headers } )
    .then( () => {
        return { ok: true, message: "Update company successfully" }
    } )
    .catch( err => {
        return ErrorHandler( err, toast, "Error updating business" )
    } )
}


// success
// "Update password successfully"
// "Passwort erfolgreich aktualisieren"
// error
// "Error updating password"
// "Fehler beim Aktualisieren des Passworts"

export const changePassword = ( data ) => async () => {
    const headers = getHeader({ ['Content-Type']: 'multipart/form-data' } )

    return axios.post( ROOT_URL + "/setting/update-password", data, { headers } )
    .then( () => {
        return { ok: true, message: "Update password successfully" }
    } )
    .catch( err => {
        return ErrorHandler( err, toast, "Error updating password" )
    } )
}

export const applyTier = tier => async () => {
    const headers = getHeader({ ['Content-Type']: 'multipart/form-data' } )

    const data = { tier }

    return axios.post( ROOT_URL + '/user/upgrade-account', data, { headers } )
    .then( () => {
        return { ok: true }
    } )
    .catch( err => {
        console.log( err )
        return ErrorHandler( err, toast, "Error applying for account upgrade." )
    } )
}

export const cancelMembership = () => async () => {
    const headers = getHeader({ ['Content-Type']: 'multipart/form-data' } )

    return axios.post( ROOT_URL + '/user/cancel-upgrade', {}, { headers } )
    .then( () => {
        return { ok: true }
    } )
    .catch( err => {
        console.log( err )
        return ErrorHandler( err, toast, "Error cancelling for account upgrade." )
    } )
}

export const { loadUser, updateUser, cleanUser } = userSlice.actions

export default userSlice.reducer