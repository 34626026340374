import axios from "axios";

const ROOT_URL = process.env.REACT_APP_API_URL

export const getAuthorization = () => {
    const access = localStorage.getItem("grocket_customer_access")

    return access || null
}

export const cleanAuthorization = () => {
    localStorage.removeItem("grocket_customer_access")
}

export const checkAuthorization = () => {
    const access = localStorage.getItem("grocket_customer_access")

    return access ? true : false
}

export const saveAuthorization = ( access ) => {
    if ( !access ) return
    
    localStorage.setItem("grocket_customer_access", access)
}

export const getHeader = ( extraField={} ) => {
    const access = getAuthorization()

    return {
        'Accept': "application/json",
        'Authorization': "Bearer " + access,
        ...extraField
    }
}

export const login = async credentials  => {
    return await axios.post( ROOT_URL + '/user/login', credentials )
    .then( resp => {
        const access_token = resp.data.access_token

        saveAuthorization( access_token )

        return { ok: true, message: "" }
    } )
    .catch( err => {
        const message = err?.response?.status === 401 ? err.response.data.message : "Error logging in. Please try again later..."

        return { ok: false, message }
    } )
}