import "./FidgetSPinner.scss";

const FidgetSpinner = ({ style = {}, className = "", fidgetStyle = {} }) => {
  return (
    <div style={style} className={`loader-container fd ${className}`}>
      <span className="loader" style={fidgetStyle}></span>
    </div>
  );
};

export default FidgetSpinner;
