import { useCallback } from "react"

const useLandpageProps = () => {
    // inputLabelProps={
    //     {
    //         style: {
    //             color: "white"
    //         },
    //         required: false
    //     }
    // }
    // inputProps={
    //     {
    //         style: {
    //             color: "white"
    //         }
    //     }
    // }

    const inputLabelPropsReturn = useCallback( ( extraProps={}, extraStyles={} ) => {
        return {
            ...extraProps,
            style: {
                color: "white",
                ...extraStyles
            },
            required: false
        }
    }, [] )

    const inputPropsReturn = useCallback( ( extraProps={}, extraStyles={} ) => {
        return {
            ...extraProps,
            style: {
                color: "white",
                ...extraStyles
            }
        }
    }, [] )

    return { inputPropsReturn, inputLabelPropsReturn }
}

export default useLandpageProps