/* eslint-disable react-hooks/exhaustive-deps */
import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import FidgetSpinner from "../../sharedComponent/FidgetSpinner";
import TextEditor, { useNewEditor } from "../../sharedComponent/TextEditor";
import { fetchSentMessages, sendMessage } from "../../../store/messagesSlice";
import './MessageModal.scss';
import english_language from '../../../shared/english_language.json';
import german_language from '../../../shared/german_language.json';

const MessageModal = ( { isOpen=false, handleClose=()=>"", defaultSubject="" } ) => {
    const user = useSelector( state => state.user.value )
    const { messages_label } = user?.language === 2 ? english_language : german_language;

    const dispatch = useDispatch()

    const editor = useNewEditor( "<p></p>", messages_label?.new_message_modal_label?.message_label || "Message" )

    const [ submitSpinner, setSubmitSpinner ] = useState( false )
    const [ subject, setSubject ] = useState( "" )
    // const [ messageBody, setMessageBody ] = useState( "" )

    const resetEverything = () => {
        setSubject( defaultSubject )
        // setMessageBody("")
        setSubmitSpinner(false)
    }

    useEffect( () => {
        if ( isOpen ) resetEverything()
    }, [ isOpen ] )

    const sendHandler = async e => {
        e.preventDefault()

        if ( !subject ) {
            toast.error( "Subject cannot be empty" )
            return
        }

        if ( editor.isEmpty ) {
            toast.error( "Message cannot be empty" )
            return
        }

        const message = editor.getHTML()

        setSubmitSpinner( true )

        const status = await dispatch( sendMessage( { subject, message } ) )
        setSubmitSpinner( false )

        if ( status.ok ) {
            toast.success( status.message )
            dispatch( fetchSentMessages() )
            handleClose()
        }
    }

    return (
        <>
            <h3> { messages_label?.new_message_modal_label?.header_label || "New Message" } </h3>
            <form className="message-form" onSubmit={ sendHandler }>
                <ul>
                    <li>
                        <TextField 
                            label={ messages_label?.new_message_modal_label?.subject_label || "Subject" }
                            value={ subject }
                            onChange={ e => setSubject( e.target.value ) }
                            fullWidth
                            variant="standard" />
                    </li>
                    <li>
                        { editor && 
                            <TextEditor 
                                editable={ true } 
                                editor={ editor }
                                editorHeight={10}
                                hasMaxHeight={true} /> }
                    </li>
                </ul>
                <div className="base-btns fd">
                    <Button 
                        variant="contained" 
                        sx={ { width: "8rem" } } 
                        onClick={ () => handleClose() } 
                        className="-dark">
                        { messages_label?.new_message_modal_label?.cancel_btn_label || "Cancel" }
                    </Button>
                    { submitSpinner ?
                        <FidgetSpinner className="-btn" style={ { width: "8rem" } }></FidgetSpinner> :
                        <Button 
                            variant="contained" 
                            sx={ { width: "8rem" } }
                            type={ "submit" }
                            className="-gradient">
                            { messages_label?.new_message_modal_label?.send_btn_label || "Send" }
                        </Button>
                     }
                </div>
            </form>
        </>
    )
}

export default MessageModal;