/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from "react";
import { Visibility,VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { login } from "../../../shared/Authorization";
import { Link, useNavigate } from "react-router-dom";
import FidgetSpinner from "../../sharedComponent/FidgetSpinner";
import useLandpageProps from "../../../useHooks/useLandpageProps";
import { LandPageContext } from "./LandPage";

const Login = () => {    
    // const user = useSelector( state => state.user.value )
    // const {
    //     email_label,
    //     password_label,
    //     submit_btn_label
    // } = user?.language === 2 ? english_language : german_language

    const navigate = useNavigate()

    const {
        inputPropsReturn,
        inputLabelPropsReturn
    } = useLandpageProps()

    const {
        whichLanguage
    } = useContext( LandPageContext )

    const [ isSubmit, setSubmit ] = useState( false )

    const [ email, setEmail ] = useState("")
    const [ password, setPassword ] = useState("")
    const [ showPassword, setShow ] = useState( false )

    const submitHandler = async e => {
        e.preventDefault()

        if ( !email.replaceAll(" ", "") ) {
            toast.error("Email cannot be empty")
            return
        }

        if ( !password.replaceAll(" ", "") ) {
            toast.error("Password cannot be empty")
            return
        }

        // if ( password.length <= 5 ) {
        //     toast.error("Password must be at least 6 characters")
        //     return
        // }

        setSubmit( true )
        const status = await login( { email, password } )

        setSubmit( false )

        if ( !status?.ok ) {
            toast.error( status.message )
        } else navigate("/dashboard")
    }
    
    return (
        <>
            <form className="form-standard" onSubmit={ submitHandler }>
                <ul className="fd">
                    <li>
                        <TextField
                            label={ "Email" }
                            variant="standard"
                            value={ email }
                            required
                            fullWidth
                            onChange={ (e) => setEmail( e.target.value ) }
                            InputLabelProps={ inputLabelPropsReturn() }
                            inputProps={ inputPropsReturn() }
                        />
                    </li>
                    <li>
                        <TextField 
                            fullWidth
                            type={ showPassword ? "text" : "password" }
                            label={ whichLanguage === 1 ? "Passwort" : "Password" } 
                            variant="standard"
                            value={ password }
                            required
                            onChange={ (e) => setPassword( e.target.value ) }
                            InputLabelProps={ inputLabelPropsReturn() }
                            InputProps={ 
                                inputPropsReturn( {
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={ { color: "white" } }
                                                aria-label="toggle password visibility"
                                                onClick={ () => setShow( !showPassword ) }>
                                                    { showPassword ? <Visibility /> : <VisibilityOff /> }
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                } )
                                // {
                                //     style: {
                                //         color: "white"
                                //     },
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <IconButton
                                //                 sx={ { color: "white" } }
                                //                 aria-label="toggle password visibility"
                                //                 onClick={ () => setShow( !showPassword ) }>
                                //                     { showPassword ? <Visibility /> : <VisibilityOff /> }
                                //             </IconButton>
                                //         </InputAdornment>
                                //     )
                                // } 
                                } />
                    </li>
                </ul>
                {   !isSubmit &&
                    <div className="base-btns">
                        <Button
                            type="submit"
                            fullWidth
                            className="-gradient"
                            variant="contained">
                            {
                                whichLanguage === 1 ? "Speichern" : "Submit"
                            }
                        </Button>
                    </div>
                }
                { isSubmit && <FidgetSpinner className="-btn" style={ { marginTop: "2rem" } } /> }
                <p className="forgot-btn">
                    <Link to={"forget-password/first-step"}> 
                        {
                            whichLanguage === 1 ? "Haben Sie Ihr Passwort vergessen?" : "Forgot your Password?"
                        }
                    </Link>
                </p>
            </form>
        </>
    )
}

export default Login