import { Button, TextField } from "@mui/material"
import { dateCleaner } from "../../../shared/CustomFunc"
import ShowReviewsTable from "./ShowReviewsTable"
import './ShowOrderModal.scss';


const PAYMENT_STATUS_DROPDOWN = [
    { 
        value: 0,
        label: "New"
    }, { 
        value: 1,
        label: "Sent Invoice"
    }, { 
        value: 2,
        label: "Paid"
    }, { 
        value: 11,
        label: "Payment Reminder 1"
    }, { 
        value: 12,
        label: "Payment Reminder 2"
    }
]

const ShowOrderModal = ( { order={}, cancelHandler=()=>"" } ) => {
    return (
        <>
            <h3>Order Information</h3>
            <ul className="upper-info fd">
                <li>
                    <TextField label="DATE" fullWidth variant="standard" type="date" disabled value={ dateCleaner( order?.created_at || "" ) } />
                </li>
                <li>
                    <TextField label="ORDER ID" fullWidth variant="standard" disabled value={ order?.id || "" } />
                </li>
            </ul>
            <ShowReviewsTable 
                reviews={ order?.order_reviews || [] } />
            <ul className="bottom-info fd">
                <li>
                    <TextField 
                        label="Unit Price" 
                        variant="standard"
                        disabled 
                        value={ order?.unit_cost || "" } />
                    <TextField label="Total Price" 
                        variant="standard"
                        disabled 
                        value={ order?.total_price || "" } />
                    <TextField label="Payment Status" 
                        variant="standard"
                        disabled 
                        value={ PAYMENT_STATUS_DROPDOWN.find( status => status.value === Number( order?.payment_status ) )?.label || "" } />
                </li>
                <li>
                    <TextField 
                        label="Remarks" 
                        variant="standard"
                        disabled
                        fullWidth
                        value={ order?.remarks || "" } />
                </li>
            </ul>
            <div className="base-btns fd">
                <Button type="button" className="-dark" sx={ { width: "7rem" } } variant="contained" onClick={ cancelHandler }>
                    Cancel
                </Button>
            </div>
        </>
    )
}

export default ShowOrderModal