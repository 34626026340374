import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getHeader } from "../shared/Authorization";
import { ErrorHandler } from "../shared/CustomFunc";

const ROOT_URL = process.env.REACT_APP_API_URL;
const INITIAL_VALUE = {
  companies: [],
  selectedCompany: {},
  cardData: {},
  graphData: [],
  dashboardOrders: [],
  ratings: {},
  reviews: [],
  modalReviews: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    value: INITIAL_VALUE,
  },
  reducers: {
    loadCompanies: (state, action) => {
      state.value.companies = action.payload.companies;
    },
    loadSelectedCompany: (state, action) => {
      const { company_id } = action.payload;
      if (!state.value.companies?.length || !company_id)
        state.value.selectedCompany = {};
      else {
        state.value.selectedCompany = state.value.companies.find(
          (comp) => comp.id === company_id
        );
      }
    },
    loadCardData: (state, action) => {
      if ("current_rating" in action.payload) {
        const {
          current_rating,
          number_of_reviews,
          negative_reviews,
          deleted_revies,
        } = action.payload;

        state.value.cardData = {
          current_rating,
          number_of_reviews,
          negative_reviews,
          deleted_reviews: deleted_revies || 0,
        };
      }
    },
    loadGraphData: (state, action) => {
      // const dates = dateArray()
      const raw_data = action.payload.raw_data;

      // const _graphData = dates.map( date =>  {
      //     const returnData = { year: date.year, month: date.month }
      //     const foundData = raw_data.find( _data => _data.month === date.month && _data.year === date.year )

      //     returnData.avg_rating = foundData?.avg_rating || 0
      //     returnData.rating = foundData?.rating || 0

      //     return returnData
      // } )

      // const _graphData = dataCreate(raw_data);
      state.value.graphData = raw_data.reverse();
    },
    loadDashboardOrders: (state, action) => {
      state.value.dashboardOrders = action.payload.orders;
    },
    loadReviews: (state, action) => {
      state.value.ratings = action.payload.ratings;
      state.value.reviews = action.payload.reviews;
    },
    // update actions

    //clean actions
    cleanCompanies: (state) => {
      state.value.companies = [];
    },
    cleanCompany: (state) => {
      state.value.selectedCompany = {};
    },
    cleanCardData: (state) => {
      state.value.cardData = {};
    },
    cleanGraphData: (state) => {
      state.value.graphData = [];
    },
    cleanDashboardOrders: (state) => {
      state.value.dashboardOrders = [];
    },
    cleanDashBoard: (state) => {
      state.value = INITIAL_VALUE;
    },
    cleanReviews: (state) => {
      state.value.ratings = {};
      state.value.reviews = [];
    },
    cleanModalReviews: (state) => {
      state.value.modalReviews = [];
    },

    // add modal reviews
    addModalReview: (state, action) => {
      let actionPayload;
      if (Array.isArray(action.payload)) {
        actionPayload = [...action.payload];
        state.value.modalReviews = [
          ...state.value.modalReviews,
          ...actionPayload,
        ];
        return;
      } else {
        actionPayload = action.payload;
        state.value.modalReviews = [...state.value.modalReviews, actionPayload];
      }
    },
    removeModalReview: (state, action) => {
      state.value.modalReviews = state.value.modalReviews.filter(
        (review) => review.id !== action.payload
      );
    },
  },
});

export const fetchCompanies = () => async (dispatch) => {
  const headers = getHeader();

  return await axios
    .get(ROOT_URL + "/user/companies", { headers })
    .then((resp) => {
      dispatch(loadCompanies(resp.data || []));

      return { ok: true };
    })
    .catch((err) => {
      console.log(err);

      return ErrorHandler(err, toast, "Error fetching companies");
    });
};

export const fetchDatas = (id) => async (dispatch) => {
  const headers = getHeader();

  return await axios
    .get(ROOT_URL + "/dashboard/ratings/" + id, { headers })
    .then((resp) => {
      dispatch(loadCardData(resp.data || {}));
      dispatch(loadGraphData({ raw_data: resp.data?.ratings_graph || [] }));

      return { ok: true };
    })
    .catch((err) => {
      console.log(err);

      return ErrorHandler(err, toast, "Error fetching data");
    });
};

export const fetchDashboardOrders = (id) => async (dispatch) => {
  const headers = getHeader();

  return await axios
    .get(ROOT_URL + "/dashboard/orders?company_id=" + id, { headers })
    .then((resp) => {
      dispatch(loadDashboardOrders(resp.data));
    })
    .catch((err) => {
      console.log(err);

      return ErrorHandler(err, toast, "Error fetching orders");
    });
};

export const addOrder = (formData) => async () => {
  const headers = getHeader({ "Content-Type": "multipart/form-data" });

  return await axios
    .post(ROOT_URL + "/order/create", formData, { headers })
    .then(() => {
      toast.success("Order has been placed successfully");
      return { ok: true };
    })
    .catch((err) => {
      console.log(err);

      return ErrorHandler(err, toast, "Error adding order");
    });
};

export const fetchReviews = (id) => async (dispatch) => {
  const headers = getHeader();

  return await axios
    .get(ROOT_URL + "/reviews-calculator/" + id, { headers })
    .then((resp) => {
      const data = {
        ratings: resp.data.ratings,
      };

      data.reviews = {
        five_star: resp.data.reviews?.five_star || resp.data.reviews[0],
        four_star: resp.data.reviews?.four_star || resp.data.reviews[1],
        three_star: resp.data.reviews?.three_star || resp.data.reviews[2],
        two_star: resp.data.reviews?.two_star || resp.data.reviews[3],
        one_star: resp.data.reviews?.one_star || resp.data.reviews[4],
      };

      dispatch(loadReviews(data));

      return { ok: true };
    })
    .catch((err) => {
      console.log(err);

      if (err?.response?.status === 404)
        return {
          ok: false,
          message: err?.response?.data?.message || "Error fetching ratings",
        };

      return ErrorHandler(err, toast, "Error fetching ratings");
    });
};

export const {
  loadCompanies,
  loadSelectedCompany,
  loadCardData,
  loadGraphData,
  loadDashboardOrders,
  loadReviews,

  cleanCompanies,
  cleanCompany,
  cleanCardData,
  cleanGraphData,
  cleanDashboardOrders,
  cleanDashBoard,
  cleanReviews,
  cleanModalReviews,

  addModalReview,
  removeModalReview,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
