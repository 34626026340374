import { useEffect, useRef, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { emailCheck } from '../../../shared/CustomFunc';
import { fetchUser, updatePersonalInformation } from '../../../store/userSlice';
import './PersonalInformation.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import english_language from '../../../shared/english_language.json';
import german_language from '../../../shared/german_language.json';
import FidgetSpinner from '../../sharedComponent/FidgetSpinner';

const PersonalInformation = () => {
    const user = useSelector( state => state.user.value )
    const { my_profile_label } = user?.language === 2 ? english_language : german_language;
    const dispatch = useDispatch()

    const [ submitSpinner, setSubmitSpinner ] = useState( false )

    const [ email, setEmail ] = useState("")
    const [ name, setName ] = useState("")
    const [ image, setImage ] = useState("");
    const [ imagePreview, setImagePreview ] = useState("")
    const [ origImage, setOrigImage ] = useState()

    const fileEl = useRef()

    const imgClickHandler = () => {
        if ( fileEl.current ) fileEl.current.click()
    }

    const imageHandler = e => {
        if ( e?.target?.files?.[0] ) setImage( e.target.files[0] )
        else {
            if ( origImage ) {
                if ( origImage !== imagePreview ) setImage( origImage )
                else setImage("")
            }
            else setImage("")
        }
    }

    useEffect( () => {
        if ( image?.name ) setImagePreview( URL.createObjectURL( image ) )
        else setImagePreview( image )
        
    }, [ image ] )

    const submitHandler = async e => {
        e.preventDefault()

        if ( !name.replaceAll(" ", "") ) {
            toast.error("Name must not be empty")
            return
        }

        if ( !email.replaceAll(" ", "") ) {
            toast.error("Email must not be empty")
            return
        }

        if ( !emailCheck( email ) ) {
            toast.error("Email must be valid")
            return
        }

        const data = {
            name,
            email
        }

        if ( email !== user?.email ) data.email = email

        if ( image !== user?.profile_url ) {
            if ( !image ) data.image_delete = 1
            else data.image = image
        }
        data.language = user.language
        setSubmitSpinner( true )

        const status = await dispatch( updatePersonalInformation( data ) )

        setSubmitSpinner( false )

        if ( status.ok ) {
            toast.success( status.message )
        }

        dispatch( fetchUser() )
    }

    useEffect( () => {
        if ( !user.id ) return

        setEmail( user.email )
        setName( user.name )
        setOrigImage( user.profile_url )
        
    }, [ user ] )

    useEffect( () => {
        if ( origImage ) {
            setImage( origImage )
            setImagePreview( origImage )
        }
        else {
            setImage( "" )
            setImagePreview( "" )
        }
    }, [ origImage ] )

    return (
        <div className="personal-information">
            <input ref={ fileEl } type="file" style={ { display: "none" } } onChange={ imageHandler } />
            <div className={`profile-picture-container ${ !imagePreview && "-empty" }`}>
                { imagePreview ?  
                        <img src={ imagePreview } alt="profile of user" onClick={ imgClickHandler } /> : 
                        <div className='no-image' onClick={ imgClickHandler }>
                            <p> { user?.name?.slice(0, 1)?.toUpperCase() || "" } </p>
                        </div> 
                }
                { imagePreview  && <p className={`delete-handler`}>
                                        <FontAwesomeIcon 
                                            icon={ solid('x') }
                                            style={ { cursor: "pointer" } }
                                            onClick={ () => imageHandler( ) } /> </p> }
            </div>
            <form className='personal-information-form fd' onSubmit={ submitHandler }>
                <TextField 
                    id="email" 
                    label={ my_profile_label?.email_label || "Email" } 
                    variant='standard'
                    type="email"
                    sx={ { maxWidth: "25rem" } }
                    value={ email }
                    onChange={ ( e ) => setEmail( e.target.value ) } />
                <TextField 
                    id="name" 
                    label={ my_profile_label?.name_label || "Name" }
                    sx={ { maxWidth: "25rem" } } 
                    variant='standard'
                    value={ name }
                    onChange={ ( e ) => setName( e.target.value ) } />
                {
                    submitSpinner ?
                        <FidgetSpinner className='-btn' style={ { marginTop: "1rem", width: "8rem" } } /> :
                        <Button
                            type='submit'
                            className='-gradient' 
                            sx={ { marginTop: "1rem", width: "8rem" } }> { my_profile_label?.save_btn_label || "Save" } </Button>
                }
            </form>
        </div>
    )
}

export default PersonalInformation;