import { useDispatch } from "react-redux"
import { cleanAuthorization } from "../shared/Authorization"
import { cleanDashBoard } from "../store/dashboardSlice"
import { cleanUser } from "../store/userSlice"

const useLogout = () => {
    const dispatch = useDispatch()

    return () => {
        
        dispatch( cleanUser() )
        dispatch( cleanDashBoard() )
        cleanAuthorization()
        window.location.reload()
    }
}

export default useLogout