/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Box, TextField } from "@mui/material";
import SectionWrapper from "../../../wrapper/SectionWrapper";
import CompanyInfo from "../../companyInfo/CompanyInfo";
import DataCard from "../../sharedComponent/DataCard";
import "./Dashboard.scss";
import PastOrderTable from "../../sharedComponent/PastOrderTable";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addModalReview,
  cleanCardData,
  cleanDashboardOrders,
  cleanGraphData,
  cleanModalReviews,
  fetchDashboardOrders,
  fetchDatas,
  loadSelectedCompany,
} from "../../../store/dashboardSlice";
// import { dataCreate, monthReturner } from "../../../shared/CustomFunc";
import { DataContext } from "../../UnderRoot";
import english_language from "../../../shared/english_language.json";
import german_language from "../../../shared/german_language.json";
import useLogout from "../../../useHooks/useLogout";
import { useScrapeReviews } from "../../../useHooks/useScrapeReviews";
import ScraperReviewsTable from "./ScraperReviewsTable";
import FidgetSpinner from "../../sharedComponent/FidgetSpinner";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import LineChart from "../../sharedComponent/LineChart";
// import PromoCards from "./PromoCards"

dayjs.extend(utc);
dayjs.extend(relativeTime);

const Dashboard = () => {
  const user = useSelector((state) => state.user.value);
  const [addOrderOpen, setAddOpen] = useState(false);

  const { fetchScrapedReviews, isFetchingReviews, scrapedReviews } =
    useScrapeReviews();

  const logout = useLogout();

  const { dashboard_datacard_label, graph_label, order_table_label } =
    user?.language === 2 ? english_language : german_language;

  const dispatch = useDispatch();
  const { isMobile } = useContext(DataContext);
  const {
    companies,
    selectedCompany,
    graphData,
    cardData,
    dashboardOrders,
    modalReviews: reviews,
  } = useSelector((state) => state.dashboard?.value);

  // const getCompanies = async () => {
  //     await dispatch( fetchCompanies() )
  // }

  // useEffect( () => {
  //     // initial fetch for companies when selectedCompany is empty
  //     if ( companies.length && !selectedCompany?.id ) dispatch( loadSelectedCompany( { company_id: companies[0].id } ) )
  // }, [ companies ] )

  // useEffect( () => {
  //     getCompanies()
  // }, [] )

  // for selectedCompany
  // if a company is selected
  // we load these dates
  // first the charts, and the cards

  const companyChangeHandler = (id) => {
    dispatch(loadSelectedCompany({ company_id: Number(id) }));
  };

  const getDatas = async () => {
    const status = await dispatch(fetchDatas(selectedCompany.id));

    if ("logout" in status) logout();
  };

  useEffect(() => {
    if (selectedCompany?.id) getDatas();

    return () => {
      dispatch(cleanGraphData());
      dispatch(cleanCardData());
    };
  }, [selectedCompany]);

  const chartData = useMemo(() => {
    // const newChartData = Array.from({ length: 30 }, (_, i) => {
    //   const date = dayjs(graphData[i]?.date)
    //     .subtract(i, "day")
    //     .format("YYYY-MM-DD");
    //   return {
    //     date,
    //     value: graphData[i]?.avg_rating ?? null,
    //     rating: graphData[i]?.rating ?? null,
    //   };
    // }).reverse();
    // return isMobile ? newChartData.slice(0, 6) : newChartData;
    const labels = Array.from(
      // { length: graphData.length < 30 ? graphData.length : 30 },
      { length: 30 },
      (_, i) => {
        const date = dayjs().local().subtract(i, "day").format("YYYY-MM-DD");
        return date;
      }
    ).reverse();

    const data = Array.from(
      // { length: graphData.length < 30 ? graphData.length : 30 },
      { length: 30 },
      (_, i) => {
        return graphData[i]?.avg_rating ?? null;
      }
    ).reverse();

    const newChartData = {
      labels,
      datasets: [
        {
          label: "Monthly Trend",
          data: isMobile ? data.slice(0, 6) : data,
          borderColor: "#49D16F",
          fill: true,
          backgroundColor: "#C9FFD8",
        },
      ],
    };

    return newChartData;
  }, [graphData, isMobile]);

  // then we fetch the orders

  const getDashboardOrders = async () => {
    dispatch(fetchDashboardOrders(selectedCompany.id));
  };

  useEffect(() => {
    if (selectedCompany?.id) {
      getDashboardOrders();
      fetchScrapedReviews({ url: selectedCompany?.url, quantity: 5 });
    }

    return () => dispatch(cleanDashboardOrders());
  }, [selectedCompany]);

  const resetHandler = () => {
    getDatas();
    getDashboardOrders();
    dispatch(cleanModalReviews());
  };

  const [checkedReviews, setCheckedReviews] = useState([]);

  const handleAddReview = (e, name, google_review_id) => {
    e.preventDefault();

    toast.success("Added review to orders");

    if (checkedReviews.length > 0) {
      const addReviews = checkedReviews.map((review, index) => {
        const id = reviews.length + index + 1;
        return {
          id,
          name: review.name,
          status: 5,
          google_review_id: review.google_review_id,
        };
      });
      dispatch(addModalReview(addReviews));
      setCheckedReviews([]);
      return;
    }

    dispatch(
      addModalReview({
        id: reviews.length + 1,
        name,
        status: 5,
        google_review_id,
      })
    );
  };

  const filteredReviews = useMemo(() => {
    const uniqueGoogleReviewIds = new Set();
    const pendingGoogleOrder = new Set();

    if (dashboardOrders.length > 0) {
      dashboardOrders.forEach((item) => {
        if (item.order_reviews) {
          item.order_reviews.forEach((review) => {
            if (review.google_review_id !== null) {
              uniqueGoogleReviewIds.add(review.google_review_id);
              pendingGoogleOrder.add(review.google_review_id);
            }
          });
        }
      });
    }

    // Filter reviews that are already added in modal
    reviews.forEach((review) => {
      if (review?.google_review_id) {
        uniqueGoogleReviewIds.add(review.google_review_id);
      }
    });

    // Use the reviewsToAdd to filter scrapedReviews
    return {
      pending: scrapedReviews.filter((review) =>
        pendingGoogleOrder.has(review.google_review_id)
      ),
      filtered: scrapedReviews.filter(
        (review) => !uniqueGoogleReviewIds.has(review.google_review_id)
      ),
    };
  }, [scrapedReviews, reviews, dashboardOrders]);

  const [reviewQuantity, setReviewQuantity] = useState(5);

  const reloadScrapedReviews = () => {
    if (!reviewQuantity) return;
    fetchScrapedReviews({
      url: selectedCompany?.url,
      quantity: parseInt(reviewQuantity),
    });
  };

  return (
    <SectionWrapper sectionClass={"dashboard"}>
      <CompanyInfo
        companies={companies}
        selectedCompany={selectedCompany || ""}
        changeHandler={companyChangeHandler}
        resetHandler={resetHandler}
        addOrderOpen={addOrderOpen}
        setAddOpen={setAddOpen}
      />
      <ul className="data-card-list dashboard fd">
        <li>
          <DataCard
            label={
              dashboard_datacard_label.current_rating_label || "Current Rating"
            }
            value={cardData.current_rating}
            color="#48D16F"
          />
        </li>
        <li>
          <DataCard
            label={
              dashboard_datacard_label.number_of_reviews || "Number of Reviews"
            }
            value={cardData.number_of_reviews}
            color="#48D16F"
          />
        </li>
        <li>
          <DataCard
            label={
              dashboard_datacard_label.negative_reviews || "NEGATIVE REVIEWS"
            }
            value={cardData.negative_reviews}
            color="#48D16F"
          />
        </li>
        <li>
          <DataCard
            label={
              dashboard_datacard_label.deleted_reviews || "Deleted Reviews"
            }
            value={cardData.deleted_reviews}
            color="#48D16F"
          />
        </li>
      </ul>
      <div className="chart-container">
        <p className="chart-legend">
          {graph_label.top_label || "Monthly Trend"}
        </p>
        {/* <DataChart chartHeight="20rem" data={chartData} isMobile={isMobile} /> */}
        <LineChart data={chartData} displayLegend={false} />
      </div>

      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: { md: "center" },
            mb: "1rem",
            flexDirection: { xs: "column", md: "row" },
            gap: "1rem",
          }}
        >
          <p style={{ fontWeight: 700 }}>Reviews</p>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <TextField
              label="Number of reviews"
              variant="standard"
              value={reviewQuantity}
              type="number"
              onChange={(e) => setReviewQuantity(e.target.value)}
            />
            <Button
              className="-accent -small"
              sx={{ minWidth: "4.5rem", padding: "0 2rem" }}
              variant="contained"
              disabled={!reviewQuantity || isFetchingReviews}
              onClick={reloadScrapedReviews}
            >
              {isFetchingReviews && filteredReviews.length > 0 ? (
                <FidgetSpinner
                  fidgetStyle={{ width: "1rem", height: "1rem" }}
                />
              ) : (
                "Reload"
              )}
            </Button>
          </Box>
        </Box>
        <ScraperReviewsTable
          lowestReviews={filteredReviews.filtered}
          pendingReviews={filteredReviews.pending}
          isFetching={isFetchingReviews}
          addReview={handleAddReview}
          setCheckedReviews={setCheckedReviews}
          checkedReviews={checkedReviews}
        />
        <Box sx={{ display: "flex", gap: "1rem", mt: "2rem" }}>
          <Button
            type="button"
            variant="contained"
            className={checkedReviews.length > 0 ? "-accent" : ""}
            sx={{ padding: "0 1.5rem", borderRadius: "1.55rem" }}
            disabled={checkedReviews.length === 0}
            onClick={handleAddReview}
          >
            Add to Orders
          </Button>
          <Button
            type="button"
            className="-dark"
            variant="contained"
            sx={{ padding: "0 1.5rem" }}
            onClick={() => setAddOpen(true)}
          >
            View Orders
          </Button>
        </Box>
      </div>

      <div className="last-orders-container">
        <p className="last-orders-legend">
          {order_table_label.last_5_label || "Last 5 Orders"}
        </p>
        <PastOrderTable orders={dashboardOrders} />
      </div>
      {/* { !user?.hasApplied && 
            <PromoCards /> } */}
    </SectionWrapper>
  );
};

export default Dashboard;
