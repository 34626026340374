import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';
import UnderRoot from './component/UnderRoot';
import { Provider } from 'react-redux';
import store from './store';

// will have to use this soon
// but not gonna use this for now
// import { ThemeProvider } from '@mui/system';

function App() {
  return (
    <Provider store={ store }>
      <Router>
          <UnderRoot />
      </Router>
    </Provider>
  );
}

export default App;
