import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useState } from "react";

const TABLE_HEAD = [
    "ID",
    "NAME",
    "STATUS",
]

const KEYS = [
    "id",
    "name",
    "status"
]

const REVIEW_STATUS_DROPDOWN = [
    {
        value: 5,
        label: "Neu"
    }, { 
        value: 0, 
        label: "Beauftragt" 
    }, { 
        value: 1, 
        label: "Weiterleitung" 
    }, { 
        value: 2, 
        label: "Widerspruch" 
    }, { 
        value: 3, 
        label: "Gescheitert" 
    }, {
        value: 4,
        label: "Gelöscht"
    }
]

const ShowReviewsTable = ( { reviews=[], removeReviewHandler=()=>{} } ) => {

    const cellGenerator = ( value, i ) => {
        if ( i === 2 ) {
            return REVIEW_STATUS_DROPDOWN.find( status => status.value === Number( value ) )?.label || ""
        }
        else return value
    }

    const [ current_page, setCurrentPage ] = useState(0)

    return (
        <>
            <TableContainer className="table-container reviews-table">
                <Table>
                    <TableHead>
                        <TableRow>
                            { TABLE_HEAD.map( ( head, i ) => <TableCell key={ i }> { head?.toUpperCase() } </TableCell> ) }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            { 
                                reviews
                                .slice( current_page * 5, current_page * 5 + 5 )
                                .map( 
                                    ( review, i ) => <TableRow key={ i }>
                                        { KEYS.map( ( key, keyI ) => <TableCell key={ keyI }> { cellGenerator( review[key], keyI ) } </TableCell> ) }
                                    </TableRow> ) 
                            }
                            {
                                reviews.length < 1 && 
                                <TableRow>
                                    <TableCell colSpan={ KEYS.length } sx={ { textAlign: "center" } } >No records exists</TableCell>
                                </TableRow>
                            }
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={ [] }
                    rowsPerPage={ 5 }
                    count={ reviews.length }
                    page={ current_page }
                    onPageChange={ ( e, newPage ) => setCurrentPage( newPage ) }
                />
            </TableContainer>
        </>
    )
}

export default ShowReviewsTable;