import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changePassword } from "../../../store/userSlice";
import './ChangePassword.scss';
import english_language from '../../../shared/english_language.json';
import german_language from '../../../shared/german_language.json';
import FidgetSpinner from "../../sharedComponent/FidgetSpinner";

const ChangePassword = () => {
    const user = useSelector( state => state.user.value )
    const { my_profile_label } = user?.language === 2 ? english_language : german_language;
    const dispatch = useDispatch()

    const [ submitSpinner, setSubmitSpinner ] = useState( false )

    const [ showOldPass, setShowOldPass ] = useState( false )
    const [ old_password, setOldPassword ]  = useState("")

    const [ showNewPass, setShowNewPass ] = useState( false )
    const [ new_password, setNewPassword ] = useState("")

    const submitHandler = async e => {
        e.preventDefault()
        if ( !new_password || !old_password ) {
            toast.error("Passwords cannot be empty")
            return
        }

        if ( new_password === old_password ) {
            toast.error("New password must not match the old password")
            return
        }
        const data = {
            password: new_password,
            password_confirmation: new_password,
            old_password
        }

        setSubmitSpinner( true )

        const status = await dispatch( changePassword( data ) )

        if ( status?.ok ) {
            toast.success( status.message )
        }
        setSubmitSpinner( false )
        setOldPassword("")
        setNewPassword("")
    }
 
    return (
        <form className="change-password-form fd" onSubmit={submitHandler}>
            <TextField 
                type={ showOldPass ? "text" : "password" }
                label={ my_profile_label?.old_password_label || "Old password" } 
                variant="standard"
                value={ old_password }
                sx={ { maxWidth: "25rem" } }
                required
                onChange={ (e) => setOldPassword( e.target.value ) }
                InputProps={ {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={ () => setShowOldPass( !showOldPass ) }>
                                    { showOldPass ? <Visibility /> : <VisibilityOff /> }
                            </IconButton>
                        </InputAdornment>
                    )
                } } />
            
            <TextField 
                type={ showNewPass ? "text" : "password" }
                label={ my_profile_label?.password_label || "Password" } 
                variant="standard"
                value={ new_password }
                sx={ { maxWidth: "25rem" } }
                required
                onChange={ (e) => setNewPassword( e.target.value ) }
                InputProps={ {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={ () => setShowNewPass( !showNewPass ) }>
                                    { showNewPass ? <Visibility /> : <VisibilityOff /> }
                            </IconButton>
                        </InputAdornment>
                    )
                } } />
            {
                submitSpinner ? 
                <FidgetSpinner className="-btn" style={ { maxWidth: "10rem", marginTop: "1rem" } } /> :    
                <Button 
                    type="submit"
                    variant="contained"
                    className="-gradient"
                    sx={ { maxWidth: "10rem", marginTop: "1rem" } }>
                        { my_profile_label?.save_btn_label || "Save" }
                </Button>   
            }
        </form>
    )
}

export default ChangePassword;