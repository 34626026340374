import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getHeader } from "../shared/Authorization";
import { ErrorHandler } from "../shared/CustomFunc";

const ROOT_URL = process.env.REACT_APP_API_URL

export const pastOrdersSlice = createSlice( {
    name: "pastOrders",
    initialState: {
        value: {
            orders: [],
            current_page: 1,
            rowsPerPage: 10,
            ordersTotal: 0
        }
    },
    reducers: {
        loadPastOrders: ( state, action ) => {
            const { data, current_page:_current_page, per_page, to } = action.payload.orders;

            state.value.orders = data
            state.value.current_page = _current_page
            state.value.rowsPerPage = per_page
            // state.value.ordersTotal = total
            state.value.ordersTotal = to
        },

        cleanPastOrders: state => {
            state.value.orders = []
            state.value.current_page = 1
            state.value.rowsPerPage = 10
            state.value.ordersTotal = 0
        }
    }
} )

export const { loadPastOrders, cleanPastOrders } = pastOrdersSlice.actions;

export const fetchPastOrders = (page=1, per_page=10) => async dispatch => {
    const headers = getHeader()

    return await axios.get( ROOT_URL + '/order/past-order?page=' + page + '&per_page=' + per_page, { headers }  )
    .then( resp => {

        dispatch( loadPastOrders( resp.data ) )

        return { ok: true }
    } )
    .catch( err => {
        console.log( err )

        return ErrorHandler( err, toast, "Error fetching orders" )
    } )
}

export default pastOrdersSlice.reducer;