import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getHeader } from "../shared/Authorization";
import { ErrorHandler } from "../shared/CustomFunc";

const ROOT_URL = process.env.REACT_APP_API_URL;

const initialState = {
  value: {
    orders: [],
    ordersTotal: 0,
  },
};

const activeOrdersSlice = createSlice({
  name: "activeOrders",
  initialState,
  reducers: {
    loadActiveOrders: (state, action) => {
      const { data, to } = action.payload.orders;

      const filteredData = data.filter((item) => {
        return (
          item.order_reviews &&
          item.order_reviews.some((review) => review.status === "5")
        );
      });

      state.value.orders = filteredData;
      // state.value.ordersTotal = total
      state.value.ordersTotal = to;
    },
    cleanActiveOrders: (state) => {
      state = initialState;
    },
  },
});

export const { loadActiveOrders, cleanActiveOrders } =
  activeOrdersSlice.actions;

export const fetchActiveOrders = () => async (dispatch) => {
  const headers = getHeader();

  return await axios
    .get(ROOT_URL + "/order/past-order", { headers })
    .then((resp) => {
      dispatch(loadActiveOrders(resp.data));

      return { ok: true };
    })
    .catch((err) => {
      console.log(err);

      return ErrorHandler(err, toast, "Error fetching orders");
    });
};

export default activeOrdersSlice.reducer;
