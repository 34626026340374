/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { dateCleaner, timeExtract } from "../../../shared/CustomFunc";
import TextEditor from "../../sharedComponent/TextEditor";
import { cleanReadingMessage, fetchReadingMessageSent, fetchReadingMessageInbox } from "../../../store/messagesSlice";
import './MessageRead.scss';
import english_language from '../../../shared/english_language.json';
import german_language from '../../../shared/german_language.json';
import useLogout from "../../../useHooks/useLogout";

const MessageRead = () => {
    const user = useSelector( state => state.user.value )
    const logout = useLogout()

    const { messages_label } = user?.language === 2 ? english_language : german_language;

    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const readingMessage = useSelector( state => state.messages.value.reading )

    const [ messageId, setMessageId ] = useState();
    const [ sender, setSender ] = useState("")

    useEffect( () => {
        if ( "messageId" in params ) {

            const paramsMessageId = Number( params.messageId )
            const from = params.from

            if ( isNaN( paramsMessageId ) ) navigate( "/dashboard" )
            if ( messageId !== paramsMessageId ) {
                setMessageId( paramsMessageId )
                setSender( from )
            }
        }
    }, [ params ] )

    const messageRead = async () => {
        let status = null;

        if ( sender === 'self' ) status = await dispatch( fetchReadingMessageSent( messageId ) )
        else status = await dispatch( fetchReadingMessageInbox( messageId ) )

        if ( "logout" in status ) logout()
    }

    useEffect( () => {
        if ( messageId && sender ) messageRead()

        return () => {
            dispatch( cleanReadingMessage() )
        }
    }, [ messageId, sender ] )

    return (
        <div className="-read-message">
            <div className="message-header">    
                <h4>{ readingMessage.subject }</h4>
                <p>{ messages_label?.message_read?.sent_on_label || "Sent on" } { timeExtract( readingMessage.created_at ) } UTC { dateCleaner( readingMessage.created_at ) } </p>
            </div>
            <p className="sender-signature">
                <span>
                    {
                        user?.id === readingMessage?.sender_id ? `${ messages_label?.messages_read?.recipient_label?.[0] || "To:" } ${ readingMessage.recipient_email }` :
                        `${ messages_label?.messages_read?.recipient_label?.[0] || "From:" } ${ readingMessage.sender_email }`
                    }
                </span>
            </p>
            <TextEditor className="message-body" content={ readingMessage?.message || "" } editable={ false } />
        </div>
    )
}

export default MessageRead;