/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Button } from "@mui/material";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./Header.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { DataContext } from "../UnderRoot";
// import { cleanAuthorization } from '../../shared/Authorization';
import { useDispatch, useSelector } from "react-redux";
// import { cleanUser } from '../../store/userSlice';
import { fetchMessages } from "../../store/messagesSlice";
import english_language from "../../shared/english_language.json";
import german_language from "../../shared/german_language.json";
// import { cleanDashBoard } from '../../store/dashboardSlice';
import useLogout from "../../useHooks/useLogout";

const AvatarStyle = {
  bgcolor: "#222222",
};

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const user = useSelector((state) => state.user.value);
  const { header_label } =
    user?.language === 2 ? english_language : german_language;

  const dispatch = useDispatch();

  const logout = useLogout();

  const { isMobile } = useContext(DataContext);

  const [navOpen, setNavOpen] = useState(false);

  const avatarClickedHandler = () => {
    if (isMobile) setNavOpen(!navOpen);
  };

  useEffect(() => {
    if (!isMobile && navOpen) setNavOpen(false);
  }, [isMobile, navOpen]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (navOpen) {
      if (isMobile) body.style.overflowY = "hidden";
    } else {
      body.style.overflowY = "visible";
    }
  }, [navOpen]);

  const logoutHandler = () => {
    // dispatch( cleanUser() )
    // dispatch( cleanDashBoard() )
    // cleanAuthorization()
    // navigate("/")
    logout();
  };

  const linkClicked = (e) => {
    e.preventDefault();

    if (navOpen) setNavOpen(false);
  };

  const receivedMessages = useSelector(
    (state) => state.messages.value.received
  );

  const getMessages = async () => {
    const status = dispatch(fetchMessages());

    if ("logout" in status) logout();
  };

  useEffect(() => {
    if (user?.id) getMessages();
  }, [user]);

  const notification = useMemo(() => {
    let _notification = 0;
    receivedMessages.forEach((message) => {
      if (message.seen === 0) _notification += 1;
    });

    return _notification;
  }, [receivedMessages]);

  const contactUsHandler = () => {
    navigate("/message");
  };

  const tier = useMemo(() => {
    if (!user?.id) return "";

    if (user.tier === 2) return "VIP";
    if (user.tier === 1) return "PREMIUM";

    return "FREE";
  }, [user]);

  const curRoute = useMemo(() => {
    // if ( pathname.includes("message") ) return "message"
    // if ( pathname.includes("profile") ) return "profile"

    return pathname.split("/")[1];
  }, [pathname]);

  return (
    <header className={`${navOpen ? "-open" : ""}`}>
      <div className="fd container">
        <div className="user-badge fd">
          <Avatar
            alt="name here"
            className="avatar"
            sx={AvatarStyle}
            src={user?.profile_url || ""}
          >
            {" "}
            {user?.name?.slice(0, 1)?.toUpperCase() || "N"}{" "}
          </Avatar>
          <div className="user-info">
            <p>{user?.name || "NAME"}</p>
            {/* <p>{ header_label?.customer_label || "Customer" }</p> */}
            <p> {tier} </p>
          </div>
          <div className="burger" onClick={avatarClickedHandler}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <nav>
          <ul>
            {/* <li onClick={ linkClicked } className={ `membership-link ${ "membership" === curRoute ? "-active" : "" }` } >
                            <Link to={"/membership"}>
                                <FontAwesomeIcon icon={solid("star")} />
                                <span>
                                    { header_label?.membership_label || "Membership Perks" }
                                </span>
                            </Link>
                        </li> */}
            <li
              onClick={linkClicked}
              className={"dashboard" === curRoute ? "-active" : ""}
            >
              <Link to={"/dashboard"}>
                <FontAwesomeIcon icon={solid("home")} />
                <span>
                  {header_label?.my_dashboard_label || "My Dashboard"}
                </span>
              </Link>
            </li>
            <li
              onClick={linkClicked}
              className={"calculator" === curRoute ? "-active" : ""}
            >
              <Link to={"/calculator"}>
                <FontAwesomeIcon icon={solid("calculator")} />
                <span>
                  {header_label?.reviews_calculator_label ||
                    "Reviews Calculator"}
                </span>
              </Link>
            </li>
            <li
              onClick={linkClicked}
              className={"active-orders" === curRoute ? "-active" : ""}
            >
              <Link to={"/active-orders"}>
                <FontAwesomeIcon icon={solid("list")} />
                <span>
                  {header_label?.active_orders_label || "Active Orders"}
                </span>
              </Link>
            </li>
            <li
              onClick={linkClicked}
              className={"past-orders" === curRoute ? "-active" : ""}
            >
              <Link to={"/past-orders"}>
                <FontAwesomeIcon icon={solid("history")} />
                <span>
                  {header_label?.my_past_orders_label || "My Past Orders"}
                </span>
              </Link>
            </li>
            <li
              onClick={linkClicked}
              className={"message" === curRoute ? "-active" : ""}
            >
              <Link to={"/message"}>
                {notification > 0 && (
                  <span className="notification">{notification}</span>
                )}
                <FontAwesomeIcon icon={solid("message")} />
                <span>{header_label?.message_label || "Messages"}</span>
              </Link>
            </li>
            <li
              onClick={linkClicked}
              className={"profile" === curRoute ? "-active" : ""}
            >
              <Link to={"/profile"}>
                <FontAwesomeIcon icon={solid("user")} />
                <span>{header_label?.my_profile_label || "My Profile"}</span>
              </Link>
            </li>
            <li onClick={linkClicked}>
              <button type="button" onClick={logoutHandler}>
                <FontAwesomeIcon icon={solid("door-open")} />
                <span>{header_label?.logout_label || "Logout"}</span>
              </button>
            </li>
          </ul>
        </nav>
        <div className="contact">
          <p> {header_label?.help_label?.first_line_label || "Need Help?"} </p>
          {/* <p> { user?.language === 2 && english_language.header_label.help_label.second_line_label } </p> */}
          <Button
            fullWidth
            className="-gradient"
            variant="contained"
            onClick={contactUsHandler}
          >
            {header_label?.help_label?.button_label}
          </Button>
        </div>
      </div>
    </header>
  );
};

export default Header;
