import { Button, TextField } from "@mui/material";
import ReviewsTable from "./ReviewsTable";
import "./AddOrderModal.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import english_language from "../../shared/english_language.json";
import german_language from "../../shared/german_language.json";
import { useDispatch, useSelector } from "react-redux";
import { addModalReview, removeModalReview } from "../../store/dashboardSlice";

const AddOrderModal = ({
  company_id = null,
  cancelHandler = () => "",
  saveHandler = () => "",
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const { modalReviews: reviews } = useSelector(
    (state) => state.dashboard.value
  );
  const { add_order_modal_label } =
    user?.language === 2
      ? english_language.company_info_label
      : german_language.company_info_label;

  const [name, setName] = useState("");
  // const [ reviews, setReviews ] = useState( [] )

  const addReviewHandler = (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("Name cannot be empty");
      return;
    }

    const id = reviews.length ? reviews[reviews.length - 1].id + 1 : 1;
    const review = { id, name, status: 5 };

    // setReviews( [ ...reviews, review ] )
    dispatch(addModalReview(review));
    setName("");
  };

  const removeReviewHandler = (id) => {
    // setReviews( reviews.filter( review => review.id !== id ) )
    dispatch(removeModalReview(id));
  };

  const saveBtnClicked = (e) => {
    e.preventDefault();

    saveHandler({
      order_reviews: JSON.stringify(
        reviews.map((review) => {
          const google_review_id = review?.google_review_id ?? null;

          if (google_review_id) {
            return {
              name: review.name,
              status: review.status,
              google_review_id,
            };
          }
          return { name: review.name, status: review.status };
        })
      ),
      unit_cost: 0,
    });
  };

  return (
    <>
      <h3> {add_order_modal_label.header_label || "Order Information"} </h3>
      <ReviewsTable
        reviews={reviews}
        removeReviewHandler={removeReviewHandler}
      />
      <div className="add-reviews fd">
        <TextField
          label="Name"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          className="-accent -small"
          sx={{ minWidth: "4.5rem", padding: "0 2rem" }}
          variant="contained"
          onClick={addReviewHandler}
        >
          {add_order_modal_label.add_btn_label || "Add"}
        </Button>
      </div>
      <div className="base-btns fd">
        {
          <Button
            type="button"
            className="-accent"
            sx={{ width: "7rem" }}
            variant="contained"
            onClick={saveBtnClicked}
          >
            {add_order_modal_label.save_btn_label || "Save"}
          </Button>
        }
        <Button
          type="button"
          className="-dark"
          sx={{ width: "7rem" }}
          variant="contained"
          onClick={cancelHandler}
        >
          {add_order_modal_label.cancel_btn_label || "Cancel"}
        </Button>
      </div>
    </>
  );
};

export default AddOrderModal;
