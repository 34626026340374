/* eslint-disable react-hooks/exhaustive-deps */
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Button, IconButton, InputAdornment, TextField } from "@mui/material"
import axios from "axios"
import { useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { ErrorHandler } from "../../../shared/CustomFunc"
import useLandpageProps from "../../../useHooks/useLandpageProps"
import FidgetSpinner from "../../sharedComponent/FidgetSpinner"
import { LandPageContext } from "./LandPage"

const ROOT_URL = process.env.REACT_APP_API_URL

const ForgetPassword = () => {
    const params = useParams()
    const navigate = useNavigate()

    const {
        whichLanguage
    } = useContext( LandPageContext )

    const { 
        inputPropsReturn,
        inputLabelPropsReturn
     } = useLandpageProps()

    const [ submitSpinner, setSubmitSpinner ] = useState( false )
    const [ successMessage, setSuccessMessage ] = useState( "" )
    const [ showPassword, setShow ] = useState( false )

    const [ value, setValue ] = useState("")

    const textfieldProps =  useMemo( () => {
        if ( params.code === "first-step" ) {
            return {
                type: "email",
                label: "Email",
                InputProps: {
                    ...inputPropsReturn()
                }
            }
        }

        return {
            type: showPassword ? "text" : "password",
            InputProps: {
                ...inputPropsReturn( {
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    sx={ { color: "white" } }
                                    aria-label="toggle password visibility"
                                    onClick={ () => setShow( !showPassword ) }>
                                        { showPassword ? <Visibility /> : <VisibilityOff /> }
                                </IconButton>
                            </InputAdornment>
                        )
                    } )
            }
        }
    }, [ params, showPassword ] )

    const submitHandler = async e => {
        e.preventDefault()

        if ( !value.replaceAll(" ", "") ) {
            toast.error("Field cannot be empty")
            return
        }

        setSubmitSpinner( true )

        if ( params.code === "first-step" ) {
            // send email

            await axios.post( ROOT_URL + '/user/forgot-password', { email: value } )
            .then( () => {
                setSuccessMessage( whichLanguage === 1 ? "Bitte überprüfen Sie Ihre E-Mail." : "Please check your email.")
            } )
            .catch( err => {
                // console.log( err )
                // toast.error( "Error sending email" )
                ErrorHandler( err, toast, "Error sending email" )

                setSubmitSpinner( false )
            } )
        } else {
            if ( !params.code ) {
                toast.error("Error authentication of link")
                return
            }

            if ( value.length < 8 ) {
                toast.error( "Password length must be 8 characters or more" )
                return
            }
            await axios.post( ROOT_URL + '/user/update-password', { code: params.code, password: value } )
            .then( () => {
                setSuccessMessage( whichLanguage === 1 ? "Sie können sich nun mit Ihrem neuen Passwort anmelden." : "You can now login with your new password" )
            } )
            .catch( err => {
                ErrorHandler( err, toast, "Error changing password" )
                // console.log( err )
                // toast.error( "Error changing password" )

                setSubmitSpinner( false )
            } )
        }
    }

    useEffect( () => {
        if ( successMessage ) {
            setTimeout( () => {
                
                setValue( "" )
                setSubmitSpinner( false )
                navigate("/")

            }, 5000 )
        }
    }, [ successMessage ] )

    return (
        <>
            <form onSubmit={ submitHandler }>
                {
                    successMessage ? 
                    <>
                        <p style={ { color: "white", margin: "2rem 0 1rem 0" } }>
                            { successMessage }
                        </p>
                        
                        <p style={ { color: "white", margin: "0 0 2rem 0" } }>
                            {
                                whichLanguage === 1 ? "Dadurch wird automatisch umgeleitet. Warten Sie mal..." : 
                                "This will automatically redirect. Please wait..." 
                                
                            }
                        </p>
                    </> :
                    <>
                        <p style={ { color: "white", margin: "1rem 0 2rem 0" } }>
                            {
                                params?.code === 'first-step' ? 
                                ( whichLanguage === 1 ? "Wir senden einen Link per E-Mail." : "We will send a link through your email." ) : 
                                ( whichLanguage === 1 ? "Bitte geben Sie Ihr neues Passwort ein" : "Please enter your new password." )
                            }
                        </p>
                        <TextField 
                            {
                                ...textfieldProps
                            }
                            label={ params?.code === 'first-step' ? "Email" : ( whichLanguage === 1 ? "Passwort" : "Password" )  }
                            value={ value }
                            onChange={ e => setValue( e.target.value ) }
                            fullWidth
                            variant="standard"
                            InputLabelProps={ inputLabelPropsReturn() }
                        />
                        <p style={ { color: "white", margin: "0.5rem 0 0 0", textAlign: "left" } }>
                            { params?.code !== 'first-step' &&
                                <small>{
                                    ( whichLanguage === 1 ? "8 Zeichen Mindestlänge" : "8 characters minimum length" ) 
                                }</small>
                            }
                        </p>
                    </>
                }
                <div className="base-btns fd">
                    {
                        !successMessage &&
                        (
                            submitSpinner ?
                            <FidgetSpinner className="-btn" /> :
                            <Button 
                                type="submit"
                                variant="contained" 
                                className="-gradient">
                                    {
                                        whichLanguage ===1 ? "Speichern" : "Submit"
                                    }
                            </Button>
                        )
                    }
                    <Button 
                        variant="contained" 
                        className={ `-white ${ successMessage ? "-success" : "" }` }
                        onClick={ () => navigate("/") }>
                             { whichLanguage ===1 ? "Geh zurück" : "Go Back" }
                    </Button>
                </div>
            </form>
        </>
    )
}

export default ForgetPassword