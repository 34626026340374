/* eslint-disable react-hooks/exhaustive-deps */
import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useMemo } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SectionWrapper from "../../../wrapper/SectionWrapper"
import { DataContext } from "../../UnderRoot";
import BusinessInformation from "./BusinessInformation";
import ChangePassword from "./ChangePassword";
import PersonalInformation from "./PersonalInformation";
import './Profile.scss';
import english_language from '../../../shared/english_language.json';
import german_language from '../../../shared/german_language.json';
import ChangeLanguage from "./ChangeLanguage";
import { useSelector } from "react-redux";

const VALID_TABS = [
    "personal_information",
    "business_information",
    "change_password",
    "language"
]

const Profile = () => {
    const user = useSelector( state => state.user.value )
    const { my_profile_label } = user?.language === 2 ? english_language : german_language
    const { pathname } = useLocation();
    const navigate = useNavigate()

    const { isTrueMobile } = useContext( DataContext )

    useEffect( () => {
        // console.log( pathname )
        if ( pathname === "/profile" ) navigate("/profile/personal_information")
    }, [ pathname ] )


    const currentValue = useMemo( () => {
        const _currentPath = pathname.replace("/profile/", "")

        const is_valid = VALID_TABS.some( tab_path => tab_path === _currentPath )

        if ( is_valid ) return _currentPath
        else return ""

    }, [ pathname ] )
  
    const tabClickHandler = ( e, newValue) => {
        navigate("/profile/" + newValue )
    }

    return (
        <SectionWrapper sectionClass={"profile"}>
            <Box className="tabBox" sx={{ borderBottom: 1, borderColor: '#C5C5C5', marginBottom: "2rem" }}>
                <Tabs value={ currentValue }
                        variant="scrollable"
                        scrollButtons={ isTrueMobile }
                        allowScrollButtonsMobile 
                        TabIndicatorProps={
                            {
                                style: {
                                    background: "#48D16F"
                                }
                            }
                        }
                        onChange={ tabClickHandler }>

                    <Tab label={ my_profile_label?.nav?.[0] || "Personal Information" } value="personal_information" sx={ { color: "#C5C5C5",  letterSpacing: '0!important', fontSize: '1rem!important'  } } />
                    <Tab label={ my_profile_label?.nav?.[1] || "Business Information" } value="business_information" sx={ { color: "#C5C5C5",  letterSpacing: '0!important', fontSize: '1rem!important' } }  />
                    <Tab label={ my_profile_label?.nav?.[2] || "Change Password" } value="change_password" sx={ { color: "#C5C5C5",  letterSpacing: '0!important', fontSize: '1rem!important' } }  />
                    <Tab label={ my_profile_label?.nav?.[3] || "Language" } value="language" sx={ { color: "#C5C5C5",  letterSpacing: '0!important', fontSize: '1rem!important' } }  />
                </Tabs>
            </Box>
            <Routes>
                <Route path="personal_information" element={ <PersonalInformation /> } />
                <Route path="business_information" element={ <BusinessInformation /> } />
                <Route path="change_password" element={ <ChangePassword /> } />
                <Route path="language" element={ <ChangeLanguage /> } />
            </Routes>
        </SectionWrapper>
    )
}

export default Profile;