import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FidgetSpinner from "../../sharedComponent/FidgetSpinner";
import { Box } from "@mui/material";
import axios from "axios";
import { saveAuthorization } from "../../../shared/Authorization";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../../store/userSlice";

const ROOT_URL = process.env.REACT_APP_API_URL;
const API_TOKEN =
  "H4Elq1-wiERju3XC2I-ljKvOERCs7qR-t-UjUBPInfIprT7HOpWTq5hnUVr-vNnUBfO1zsSmRqDr_7XC6hL64-17QvHJETMAuWlv5Bc_rs9luLmyRiNIg41hrMQXDg-AZ99nPz061Rw0pZTrGZI1dsZMr0tr3hCbsuxHkmtfjw2qBf";

const AdminLogin = () => {
  const navigate = useNavigate();
  const { encodedEmail } = useParams();
  const dispatch = useDispatch();

  const loginClient = async () => {
    const formData = new FormData();
    formData.append("api_token", API_TOKEN);
    formData.append("email", window.atob(encodedEmail));

    try {
      const response = await axios.post(
        ROOT_URL + "/user/admin-login",
        formData
      );
      const access_token = response.data.access_token;
      saveAuthorization(access_token);
      await dispatch(fetchUser());
      navigate("/dashboard");
    } catch (err) {
      const message =
        err?.response?.status === 401
          ? err.response.data.message
          : "Error logging in. Please try again later...";
      toast.error(message);
      navigate("/");
    }
  };

  useEffect(() => {
    if (encodedEmail) {
      loginClient();
    }
    //eslint-disable-next-line
  }, [encodedEmail]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FidgetSpinner fidgetStyle={{ height: "1.5rem", width: "1.5rem" }} />
      <span style={{ color: "white" }}>Logging in to Client's Account...</span>
    </Box>
  );
};

export default AdminLogin;
