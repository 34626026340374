import './ToggleButton.scss';

const ToggleButton = ( { toggleSize="", sx={}, switchOn=false, handleSwitch=()=>"" } ) => {
    return (
        <div style={ sx } className={`toggle-container fd ${ switchOn ? "-active" : "" } ${ toggleSize }`}>
            <div className={ `toggle-button-container fd` } onClick={ () => handleSwitch() }>
                <div className='toggle-button'>

                </div>
            </div>
        </div>
    )
}

export default ToggleButton;