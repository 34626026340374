/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { cleanSentMessages, fetchSentMessages } from "../../../store/messagesSlice";
import SectionWrapper from "../../../wrapper/SectionWrapper"
import MessageInbox from "./MessageInbox";
import MessageModal from "./MessageModal";
import MessageRead from "./MessageRead";
import MessageSent from "./MessageSent";
import './Message.scss';
import english_language from '../../../shared/english_language.json';
import german_language from '../../../shared/german_language.json';
import MessageNav from "./MessageNav";
import useLogout from "../../../useHooks/useLogout";

const Message = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const logout = useLogout()

    const user = useSelector( state => state.user.value )

    const { messages_label } = user?.language === 2 ? english_language : german_language

    const { pathname } = useLocation()

    const [ isOpen, setIsOpen ] = useState( false )

    const getMessages = async () => {
        const status = await dispatch( fetchSentMessages() )

        if ( "logout" in status ) logout()
    }


    useEffect( () => {
        // put the fetch for sent message on this parent component,
        // since the values are dependent on the user itself, not by others
        // therefore we don't need to keep updating

        getMessages()

        return () => {
            cleanSentMessages()
            dispatch( cleanSentMessages() )
        }
    }, [] )

    const pathShowMessages = useMemo( () => {
        if ( pathname === "/message" ) return true
        if ( pathname === "/message/sent" ) return true

        return false
    }, [ pathname ] )

    const readingMessage = useSelector( state => state.messages.value.reading )

    const [ defaultSubject, setDefaultSubject ] = useState("");
    // const [ sendToDefault, setSendToDefault ] = useState( null )
    
    const handleReply = () => {
        // if ( user?.id === readingMessage.sender_id )  setSendToDefault( readingMessage.recipient_email )
        // else setSendToDefault( readingMessage.sender_email )

        setDefaultSubject( readingMessage.subject )
    }

    useEffect( () => {
        if ( defaultSubject ) setIsOpen( true )
    }, [ defaultSubject ] )

    return (
        <SectionWrapper sectionClass={"message"}>
            <Modal  
                open={ isOpen }
                className={'modal-parent -message'}>
                    <Box 
                        className='modal-box -message-modal' 
                        sx={ { width: "100%", maxWidth: "35rem;" }}>

                            <MessageModal 
                                isOpen={ isOpen }
                                defaultSubject={ defaultSubject }
                                handleClose={ () => {
                                    setIsOpen( false )
                                    setDefaultSubject( "" )
                                } } />

                    </Box>
            </Modal>
            {
                pathShowMessages && <MessageNav />
                // <Box className="tabBox" sx={{ width: "100%", maxWidth: "25rem", borderBottom: 1, borderColor: '#C5C5C5', marginBottom: "2rem" }}>
                //     <Tabs
                //         allowScrollButtonsMobile 
                //         TabIndicatorProps={
                //             {
                //                 style: {
                //                     background: "#48D16F"
                //                 }
                //             }
                //         } value={ pathname || "" }>
                //         <Tab 
                //             value="/message" 
                //             label={ messages_label?.nav?.[0] || "My Inbox" }
                //             sx={ { color: "#C5C5C5",  letterSpacing: '0!important', fontSize: '1rem!important'  } }  
                //             onClick={ () => navigate("/message") } >
                //         </Tab>
                //         <Tab 
                //             value="/message/sent"
                //             label={ messages_label?.nav?.[1] || "Sent Messages" }
                //             sx={ { color: "#C5C5C5",  letterSpacing: '0!important', fontSize: '1rem!important'  } }  
                //             onClick={ () => navigate("/message/sent") } >
                //         </Tab>
                //     </Tabs>
                // </Box>
            }
            {
                <Routes>
                    <Route index element={ <MessageInbox /> } />
                    <Route path="/sent" element={ <MessageSent /> } />
                    <Route path="/read/:from/:messageId" element={ <MessageRead /> } />
                </Routes>
            }
            {
                pathShowMessages ? 
                    <Button 
                        variant="contained" 
                        sx={ { marginTop: "2rem", width: "100%", maxWidth: "16rem" } }
                        className="-gradient"
                        onClick={ () => setIsOpen( true )  }>
                            { messages_label?.message_btn_label || "Create New Message" }
                    </Button>
                    :
                    <div className="message-base-btns fd">
                        <Button 
                            variant="contained" 
                            sx={ { marginTop: "2rem", width: "100%", maxWidth: "13rem" } }  
                            className="-dark"
                            onClick={ () => navigate( -1 )  }>
                                { messages_label?.message_read?.back_btn_label || "Go Back" }
                        </Button>
                        <Button 
                            variant="contained"
                            sx={ { marginTop: "2rem", width: "100%", maxWidth: "13rem" } }  
                            className="-gradient"
                            onClick={ () => handleReply()  }>
                                { messages_label?.message_read?.reply_btn_label || "Reply" }
                        </Button>
                    </div>
            }
            
        </SectionWrapper>
    )
}

export default Message;