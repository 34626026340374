/* eslint-disable react-hooks/exhaustive-deps */
import { Button, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import english_language from '../../../shared/english_language.json';
import german_language from '../../../shared/german_language.json';
import { fetchCompanies } from "../../../store/dashboardSlice";
import { updateCompany } from "../../../store/userSlice";
import FidgetSpinner from "../../sharedComponent/FidgetSpinner";

const BusinessInformationModal = ( { company={}, cancelHandler=()=>"" } ) => {
    const user = useSelector( state => state.user.value )
    const dispatch = useDispatch()
    const { my_profile_label } = user?.language === 2 ? english_language : german_language;

    const [ id, setId ] = useState( null )
    const [ name, setName ] = useState( "" )
    const [ url, setURL ] = useState( "" )

    useEffect( () => {
        if ( id !== company?.id ) {
            if ( company?.id ) {
                setId( company.id )
                setName( company.name )
                setURL( company.url )
            } else {
                setId( null )
                setName( "" )
                setURL( "" )
            }
        }
    }, [ company ] )

    const [ submitSpinner, setSubmitSpinner ] = useState( false )

    const submitHandler = async e => {
        e.preventDefault()

        if ( !name ) {
            toast.error( 'Name cannot be empty' )
            return
        }

        if ( !url ) {
            toast.error( 'URL cannot be empty' )
            return
        }

        if ( name === company.name && url === company.url ) {
            return
        }
        
        setSubmitSpinner( true )

        await dispatch( updateCompany( id, { name, url } ) )
        dispatch( fetchCompanies() )

        setSubmitSpinner( false )
        cancelHandler()
    }

    // const [ deleteSpinner, setDeleteSpinner ] = useState( false )
    // const deleteHandler = async () => {
    //     setDeleteSpinner( true )
    //     await dispatch( updateCompany( id, { name, url, status: 0 } ) )
    //     setDeleteSpinner( false )
    //     dispatch( fetchCompanies() )

    //     cancelHandler()
    // }

    return (
        <>
        <h3>
            { my_profile_label.update_business_label || "Update Business" }
        </h3>
        <form  className="form-standard" onSubmit={ submitHandler }>
            <ul className="fd">
                <li>    
                    <TextField 
                        variant="standard"
                        label={ my_profile_label.business_name_label || "Name" }
                        value={ name }
                        fullWidth
                        onChange={ e => setName( e.target.value ) } />
                </li>
                <li>
                    <TextField 
                        variant="standard"
                        label={ my_profile_label.business_url_label || "URL" }
                        value={ url }
                        fullWidth 
                        onChange={ e => setURL( e.target.value ) } />
                </li>
            </ul>
            <small style={ { color: "rgb(197, 197, 197)"} }> { my_profile_label.business_url_reminder_label || "You cannot edit the url." } </small>
            <div className="base-btns fd" style={ { marginTop: "2rem" } }>
                {   
                    submitSpinner ?
                    <FidgetSpinner className="-btn" style={ { width: "8rem" } } /> :
                    <Button
                        type={ "submit" }
                        variant="contained"
                        // disabled={ deleteSpinner }
                        className="-gradient"
                        sx={ { width: "8rem" } }>
                            {
                                my_profile_label.business_submit_btn_label || "Submit"
                            }
                    </Button>
                }
                {/* {
                    deleteSpinner ? 
                    <FidgetSpinner className="-btn -dark" style={ { width: "8rem" } } /> :
                    <Button 
                        variant="contained"
                        className="-dark"
                        disabled={ submitSpinner }
                        onClick={ deleteHandler }
                        sx={ { width: "8rem" } }
                    >
                        {
                            my_profile_label.business_delete_btn_label || "Delete"
                        }
                    </Button>
                } */}
                <Button
                    type={ "button" }
                    variant="contained"
                    className="-dark"
                    sx={ { width: "8rem" } }
                    onClick={ cancelHandler }>
                        {
                            my_profile_label.business_cancel_btn_label || "Cancel"
                        }
                </Button>
            </div>
        </form>
        </>
    )
}

export default BusinessInformationModal