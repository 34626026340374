import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FidgetSpinner from "../../sharedComponent/FidgetSpinner";
import StarsIcons from "../../sharedComponent/StarsIcons";

const COLUMNS = [
  { label: "NAME" },
  { label: "RATING" },
  { label: "REVIEW" },
  { label: "ACTION" },
];

const ScraperReviewsTable = ({
  lowestReviews,
  pendingReviews,
  addReview,
  isFetching = false,
  setCheckedReviews,
  checkedReviews,
}) => {
  const handleCheck = (id) => {
    const isChecked = checkedReviews.find(
      (review) => review.google_review_id === id
    );

    if (isChecked) {
      setCheckedReviews(
        checkedReviews.filter((rev) => rev.google_review_id !== id)
      );
    } else {
      const foundClient = lowestReviews.find(
        (review) => review.google_review_id === id
      );
      setCheckedReviews([...checkedReviews, foundClient]);
    }
  };

  const handleCheckAll = () => {
    if (
      checkedReviews.length > 0 ||
      checkedReviews.length === lowestReviews.length
    ) {
      setCheckedReviews([]);
    } else {
      setCheckedReviews([...lowestReviews]);
    }
  };

  return (
    <TableContainer sx={{ maxHeight: "300px", overflow: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ background: "#71EF95", fontWeight: "bold" }}>
              <Checkbox
                indeterminate={
                  checkedReviews.length > 0 &&
                  checkedReviews.length < lowestReviews.length
                }
                checked={
                  checkedReviews.length > 0 &&
                  checkedReviews.length === lowestReviews.length
                }
                onChange={handleCheckAll}
              />
            </TableCell>
            {COLUMNS.map((col, index) => (
              <TableCell
                key={index}
                sx={{ background: "#71EF95", fontWeight: "bold" }}
              >
                {col.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {lowestReviews.length === 0 && (
            <TableRow sx={{ background: "#F5F5F5" }}>
              <TableCell sx={{ textAlign: "center" }} colSpan={5}>
                {isFetching ? (
                  <FidgetSpinner
                    fidgetStyle={{ width: "2rem", height: "2rem" }}
                  />
                ) : null}
              </TableCell>
            </TableRow>
          )}

          {pendingReviews.map((review, index) => {
            return (
              <TableRow
                key={index}
                sx={{
                  background: "rgba(0, 0, 0, 0.12)",
                }}
              >
                <TableCell>{null}</TableCell>
                <TableCell sx={{ color: "rgba(0, 0, 0, 0.26)" }}>
                  {review.name}
                </TableCell>
                <TableCell>
                  <StarsIcons stars={review.rating} showLabels={false} />
                </TableCell>
                <TableCell sx={{ color: "rgba(0, 0, 0, 0.26)" }}>
                  {review.description}
                </TableCell>
                <TableCell
                  sx={{
                    color: "rgba(0, 0, 0, 0.26)",
                  }}
                >
                  <span
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Order Submitted
                  </span>
                </TableCell>
              </TableRow>
            );
          })}

          {lowestReviews.map((review, index) => {
            return (
              <TableRow key={index} sx={{ background: "#F5F5F5" }}>
                <TableCell>
                  <Checkbox
                    checked={checkedReviews.some(
                      (checkedRev) =>
                        checkedRev.google_review_id === review.google_review_id
                    )}
                    onChange={() => handleCheck(review.google_review_id)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </TableCell>
                <TableCell>{review.name}</TableCell>
                <TableCell>
                  <StarsIcons stars={review.rating} showLabels={false} />
                </TableCell>
                <TableCell>{review.description}</TableCell>
                <TableCell
                  sx={{
                    color: "#39a758",
                  }}
                >
                  <span
                    style={{
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                    onClick={(e) =>
                      addReview(e, review.name, review.google_review_id)
                    }
                  >
                    Add Review
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScraperReviewsTable;
