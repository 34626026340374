import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "./LineChart.scss";
import { useMemo } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const LineChart = ({
  datasetIdKey = "id",
  displayLegend = false,
  data,
  height = "18rem",
}) => {
  const [minDataValue, maxDataValue] = useMemo(() => {
    const dataWithoutNull = data.datasets[0].data.filter(
      (value) => value !== null
    );

    let minDataValue =
      dataWithoutNull.length > 0 ? Math.min(...dataWithoutNull) : 0;
    let maxDataValue =
      dataWithoutNull.length > 0 ? Math.max(...dataWithoutNull) : 5;

    minDataValue - 0.5 > 1
      ? (minDataValue = minDataValue - 0.5)
      : (minDataValue = 1);

    maxDataValue + 0.5 < 5
      ? (maxDataValue = maxDataValue + 0.5)
      : (maxDataValue = 5);

    return [minDataValue, maxDataValue];
  }, [data]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 16,
          autoSkip: true,
          maxTicksLimit: 5,
          align: "inner",
        },
      },
      y: {
        min: minDataValue,
        max: maxDataValue,
        type: "linear",
        beginAtZero: true,
        title: {
          display: false,
        },
        ticks: {
          padding: 16,
          // maxTicksLimit: 5,
        },
      },
    },
    plugins: {
      legend: {
        display: displayLegend,
        // position: 'bottom',
      },
    },
  };

  return (
    <div style={{ height }}>
      <Line datasetIdKey={datasetIdKey} data={data} options={options} />
    </div>
  );
};

export default LineChart;
