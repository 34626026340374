import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@mui/material"
import { useSelector } from "react-redux";
import { dateCleaner } from "../../shared/CustomFunc"
import english_language from '../../shared/english_language.json'
import german_language from '../../shared/german_language.json';

// const TABLE_HEAD = [
//     "Order ID",
//     "Date",
//     "Number of Reviews",
//     "Price",
//     "Status"
// ]

// const TABLE_HEAD_WITH_COMPANY = [
//     "Order ID",
//     "Date",
//     "Company",
//     "Number of Reviews",
//     "Price",
//     "Status"
// ]

const KEYS = [
    "id",
    "created_at",
    "order_reviews",
    "total_price",
    "payment_status"
]



const KEYS_WITH_COMPANY = [
    "id",
    "created_at",
    "company_id",
    "order_reviews",
    "total_price",
    "payment_status"
]

const PAYMENT_STATUS_DROPDOWN = [
    { 
        value: 0,
        label: "New"
    }, { 
        value: 1,
        label: "Sent Invoice"
    }, { 
        value: 2,
        label: "Paid"
    }, { 
        value: 11,
        label: "Payment Reminder 1"
    }, { 
        value: 12,
        label: "Payment Reminder 2"
    }
]

// data is the data to be displayed
// rowsPerPage, paginationActive for pagination
// rowClickable adds the className for clickable rows
// rowHandler is the function, if rowClickable is false, this quickly exits without doing anything
// rowHandlerData are lists of keys for the object per item in the data Array
// this will create an object that will be passed on rowHandler function
const PastOrderTable = ( 
    { 
        orders=[], 
        rowsPerPage=5, 
        current_page=0,
        pageChangeHandler=()=>"",
        ordersTotal=5,
        paginationActive=false, 
        rowClickable=false, 
        rowHandler=()=>"",
        rowHandlerData=[],
        companies=[],
        showCompany=false
    } ) => {
    const user = useSelector( state => state.user.value );
    const { order_table_label } = user?.language === 2 ? english_language : german_language;

    const rowClickHandler = ( rowClickData ) => {
        if ( !rowClickable ) return
        
        const rowData = {}

        rowHandlerData.forEach( item => rowData[item] = rowClickData[item] )

        rowHandler( rowData )
    }

    const cellGenerator = ( value, i, key="" ) => {
        if ( i === 1 ) {
            return dateCleaner( value )
        }

        if ( showCompany ) {
            if ( i === 2 ) {
                return companies.find( company => company.id === value )?.name || "None"
            }
            if ( i === 3 ) {
                return value.length
            }
            if ( i === 5 ) {
                return PAYMENT_STATUS_DROPDOWN.find( status => status.value === Number( value ) )?.label || ""
            }
        } else {
            if ( i === 2 ) {
                return value.length
            }
            if ( i === 4 ) {
                return PAYMENT_STATUS_DROPDOWN.find( status => status.value === Number( value ) )?.label || ""
            }
        }
         
        return value
    }

    const cellStyle = key => {
        if ( key === "company_id" ) {
            return { maxWidth: "15rem" }
        }
        return {}
    }

    return (
        <TableContainer
            className="table-container"
            component={ Paper }>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                ( showCompany ? order_table_label.table_header_with_company : order_table_label.table_header )
                                    .map( ( head, i ) => <TableCell key={ i } sx={ { background: "#71EF95", fontWeight: 'bold'}} > { head?.toUpperCase() } </TableCell> )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            orders
                            .map( ( order, i ) => <TableRow key={ i } className={`${ rowClickable ? "clickable-row" : "" }`} onClick={ () => rowClickHandler( order ) }>
                                {
                                    ( showCompany ? KEYS_WITH_COMPANY : KEYS ).map( ( key, keyI ) => <TableCell key={ keyI } sx={ { background: "#F5F5F5", ...cellStyle(key) }}>  { cellGenerator( order[key], keyI, key ) } </TableCell> )
                                }
                            </TableRow> )
                        }
                        {
                            orders.length < 1 && 
                            <TableRow>
                                <TableCell colSpan={ KEYS_WITH_COMPANY.length } sx={ { textAlign: "center" } } >No records exists</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
                {
                    paginationActive &&
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={ [] }
                        rowsPerPage={ rowsPerPage }
                        page={ current_page }
                        count={ ordersTotal }
                        onPageChange={ ( e, newPage ) => pageChangeHandler( newPage ) }
                    />
                }
        </TableContainer>
    )
}

export default PastOrderTable