/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, Modal, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CompanyTable from "./CompanyTable";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addCompany } from "../../../store/userSlice";
import { fetchCompanies } from "../../../store/dashboardSlice";
import './BusinessInformation.scss';
import english_language from '../../../shared/english_language.json';
import german_language from '../../../shared/german_language.json';
import BusinessInformationModal from "./BusinessInformationModal";
import { FunctionContext } from "../../UnderRoot";
import FidgetSpinner from "../../sharedComponent/FidgetSpinner";

const BusinessInformation = () => {
    const user = useSelector( state => state.user.value )
    const { my_profile_label } = user?.language === 2 ? english_language : german_language;

    const dispatch = useDispatch()
    const { companies } = useSelector( state => state.dashboard.value )

    const [ submitSpinner, setSubmitSpinner ] = useState( false )

    const [ name, setName ] = useState("")
    const [ url, setURL ] = useState("")

    const submitHandler = async e => {
        e.preventDefault()

        if ( !name ) {
            toast.error("Company name must not be empty")
            return
        }

        const data = { name, url }

        setSubmitSpinner( true )
        
        toast.info("This make take awhile. Please wait.")

        const status = await dispatch( addCompany( data ) )
        
        setSubmitSpinner( false )
        setName( "" )
        setURL( "" )

        if ( status?.ok ) {
            dispatch( fetchCompanies() )
            toast.success( status.message )
        }
    }
    
    const { modalOpen } = useContext( FunctionContext );
    const [ selectedCompany, setSelectedCompany ] = useState( {} )

    const businessClickedHandler = ( company ) => {
        setSelectedCompany( company )
    }

    useEffect( () => {
        modalOpen( selectedCompany?.id ? true : false )
    }, [ selectedCompany ] )

    return (
        <>
            <CompanyTable companies={ companies } rowClickable={ true } rowClickHandler={ businessClickedHandler } />
            <Modal 
                className="modal-parent" 
                open={ selectedCompany.id ? true : false }>
                    <Box 
                        className="modal-box"
                        sx={ { width: "100%", maxWidth: "35rem" } }>
                            <BusinessInformationModal 
                                company={ selectedCompany }
                                cancelHandler={ () => setSelectedCompany( {} ) } />
                    </Box>
            </Modal>
            <form className="business-info-form fd" onSubmit={ submitHandler }>
                <TextField 
                    variant="standard" 
                    label={ my_profile_label?.business_name_label || "Business Name" } 
                    sx={ { flex: 1 } }
                    value={ name }
                    onChange={ ( e ) => setName( e.target.value ) } />
                <TextField 
                    variant="standard" 
                    label={ my_profile_label?.business_url_label || "Business URL" } 
                    sx={ { flex: 1 } }
                    value={ url }
                    onChange={ ( e ) => setURL( e.target.value ) } />
                {
                    submitSpinner ?
                    <FidgetSpinner style={ { width: "8rem"} } className="-btn"  /> :
                    <Button type="submit" variant="contained" sx={ { width: "8rem" } } className="-gradient" >
                        { my_profile_label?.business_add_btn_label || "Add" }
                    </Button>
                }
            </form>
        </>
    )
}

export default BusinessInformation;