// import { useDispatch } from "react-redux"
// import { cleanDashBoard } from "../store/dashboardSlice"
// import { cleanUser } from "../store/userSlice"
// import { cleanAuthorization } from "../shared/Authorization"

/* eslint-disable no-useless-escape */
export const ErrorHandler = ( err, toast, defaultMessage="Error running task" ) => {
    // const dispatch = useDispatch()

    console.log( err )

    if ( !err?.response?.data?.message ) {
        if ( toast ) toast.error( defaultMessage )
        
        return { ok: false, message: defaultMessage }
    }

    if ( err?.response?.status !== 408 && err?.response?.status !== 422 && err.response.status !== 401 ) {
        if ( toast ) toast.error( defaultMessage )
        
        return { ok: false, message: defaultMessage }
    }

    if ( err?.response?.status === 401 ) {
        // redirect here to log out
        // dispatch( cleanUser() )
        // dispatch( cleanDashBoard() )
        // cleanAuthorization()
        // window.location.reload()
        return { ok: false, logout: true, message: "User not authorized" }
    }
    
    const message = err?.response?.data?.message.slice(0, 1).toUpperCase() + err?.response?.data?.message.slice( 1 )

    if ( toast ) toast.error( message )

    return { ok: false, message }
}

export const dateTimeFormat = date => {
    if ( !date ) return date
    
    const _date = date.replace('T', " ")
    if ( _date.length > 16 ) return _date.slice(0, 17) + "00"
    else return _date + ":00"
}

export const dateCleaner = date => {
    if ( !date ) return ""
    return date.replace("T", " ").slice(0, 10)
}

export const dateArray = () => {
    const date = new Date()

    date.setDate(1);

    const dates = []

    for( let i = 0; i < 12; i++ ) {
        dates.push( { year: date.getFullYear(), month: date.getMonth() + 1 } )
        date.setMonth( date.getMonth() - 1 )
    }

    return dates
}

export const monthReturner = ( monthNum, ver="long" ) => {
    if ( monthNum === 1 ) return ver === "long" ? "January" : "Jan"
    if ( monthNum === 2 ) return ver === "long" ? "February" : "Feb"
    if ( monthNum === 3 ) return ver === "long" ? "March" : "Mar"
    if ( monthNum === 4 ) return ver === "long" ? "April" : "Apr"
    if ( monthNum === 5 ) return ver === "long" ? "May" : "May"
    if ( monthNum === 6 ) return ver === "long" ? "June" : "Jun"
    if ( monthNum === 7 ) return ver === "long" ? "July" : "Jul"
    if ( monthNum === 8 ) return ver === "long" ? "August" : "Aug"
    if ( monthNum === 9 ) return ver === "long" ? "September" : "Sep"
    if ( monthNum === 10 ) return ver === "long" ? "October" : "Oct"
    if ( monthNum === 11 ) return ver === "long" ? "November" : "Nov"
    if ( monthNum === 12 ) return ver === "long" ? "December" : "Dec"
}

export const padZero = num => {
    if ( !num ) return num
    if ( num < 10 ) return `0${num}`
    else return num
}

export const emailCheck = email => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test( email )
}

export const dataCreate = ( raw_data = [] ) => {
    const dates = dateArray()

    return dates.map( date =>  {
        const returnData = { year: date.year, month: date.month }
        const foundData = raw_data.find( _data => _data.month === date.month && _data.year === date.year )

        returnData.avg_rating = foundData?.avg_rating || 0
        returnData.rating = foundData?.rating || 0

        return returnData
    } )
}

export const timeExtract = date => date ? date.slice(11, 16) : ""

export const storageLoginLanguageSet = language => localStorage.setItem( "grocket_customer_login_language", language )

export const storageLoginLanguageGet = () => {
    return localStorage.getItem("grocket_customer_login_language")
}