import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchUser, updatePersonalInformation } from "../../../store/userSlice";
import useLogout from "../../../useHooks/useLogout";
import ToggleButton from "../../sharedComponent/ToggleButton"
import './ChangeLanguage.scss';

const ChangeLanguage = () => {
    const logout = useLogout()
    const dispatch = useDispatch()

    const user = useSelector( state => state.user.value )

    const changeLanguage = async () => {
        const status = await dispatch( updatePersonalInformation( { name: user.name, email: user.email, language: user?.language === 1 ? 2 : 1 } ) )

        if ( status.ok ) toast.success( status.message )

        const statusFetch = dispatch( fetchUser() )

        if ( "logout" in statusFetch ) logout()
    }

    
    return (
        <div className="language-switch-con fd">
            <p>German</p>
            <ToggleButton 
                toggleSize="small" 
                switchOn={ user?.language === 1 ? true : false }
                handleSwitch={ changeLanguage } />
            <p>English</p>
        </div>
    )
}

export default ChangeLanguage