import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material"
import { useState } from "react";
import { useSelector } from "react-redux";
import english_language from '../../../shared/english_language.json';
import german_language from '../../../shared/german_language.json';
import './CompanyTable.scss';

// const TABLE_HEAD = [
//     "NAME",
//     "URL",
// ]

const KEYS = [
    "name",
    "url"
]


const CompanyTable = ( { companies=[], rowClickable=false, rowClickHandler=()=>{} } ) => {
    const user = useSelector( state => state.user.value )
    const { my_profile_label } = user?.language === 2 ? english_language : german_language;
    const [ current_page, setCurrentPage ] = useState( 0 )

    return (
        <TableContainer className="table-container">
            <Table className="company-table">
                <TableHead>
                    <TableRow>
                        { 
                            my_profile_label?.business_table_header.map( ( head, i ) => <TableCell key={ i } sx={ { background: "#71EF95", fontWeight: 'bold' }} > { head?.toUpperCase() } </TableCell> ) 
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                        {
                            companies
                            .slice( current_page * 5, current_page * 5 + 5 )
                            .map( ( company, i ) => <TableRow key={ i } className={`${ rowClickable ? "clickable-row" : "" }`} onClick={ () => rowClickHandler( company ) }>
                                {
                                    KEYS.map( ( key, keyI ) => <TableCell key={ keyI } sx={ { background: "#F5F5F5" } }> <span> { company[key] } </span> </TableCell> )
                                }
                            </TableRow> )
                        }
                        {
                            companies.length < 1 && 
                            <TableRow>
                                <TableCell colSpan={ KEYS.length } sx={ { textAlign: "center" } } >No records exists</TableCell>
                            </TableRow>
                        }
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                rowsPerPageOptions={ [] }
                rowsPerPage={ 5 }
                page={ current_page }
                count={ companies.length }
                onPageChange={ ( e, newPage ) => setCurrentPage( newPage ) }
            />
        </TableContainer>
    )
}

export default CompanyTable;