import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useState } from "react";

const TABLE_HEAD = [
    "ID",
    "NAME",
    "STATUS",
    "DELETE"
]

const KEYS = [
    "id",
    "name",
    "status"
]

const REVIEW_STATUS_DROPDOWN = [
    {
        value: 5,
        label: "Neu"
    }, { 
        value: 0, 
        label: "Beauftragt" 
    }, { 
        value: 1, 
        label: "Weiterleitung" 
    }, { 
        value: 2, 
        label: "Widerspruch" 
    }, { 
        value: 3, 
        label: "Gescheitert" 
    }, {
        value: 4,
        label: "Gelöscht"
    }
]

const ReviewsTable = ( { reviews=[], removeReviewHandler=()=>{} } ) => {

    const cellGenerator = ( value, i ) => {
        if ( i === 2 ) {
            return REVIEW_STATUS_DROPDOWN.find( status => status.value === Number( value ) )?.label || ""
        }
        else return value
    }

    const [ current_page, setCurrentPage ] = useState(0)

    return (
        <TableContainer className="table-container reviews-table">
            <Table>
                <TableHead>
                    <TableRow>
                        { TABLE_HEAD.map( ( label, i ) => <TableCell key={ i }> { label } </TableCell> ) }
                    </TableRow>
                </TableHead>
                <TableBody>
                        { 
                            reviews
                            .slice( current_page * 5, current_page * 5 + 5 )
                            .map( 
                                ( review, i ) => <TableRow key={ i }>
                                    { KEYS.map( ( key, keyI ) => <TableCell key={ keyI }> { cellGenerator( review[key], keyI ) } </TableCell> ) }
                                    
                                    <TableCell sx={ { fontSize: "1rem", textAlign: "center", color: "red" } }>
                                        <FontAwesomeIcon 
                                            icon={ solid('x') }
                                            style={ { cursor: "pointer" } }
                                            onClick={ () => removeReviewHandler( review?.id ) } />
                                    </TableCell>
                                </TableRow> ) 
                        }
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                rowsPerPageOptions={ [] }
                rowsPerPage={ 5 }
                count={ reviews.length }
                page={ current_page }
                onPageChange={ ( e, newPage ) => setCurrentPage( newPage ) }
            />
        </TableContainer>
    )
}

export default ReviewsTable;