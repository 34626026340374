/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadSelectedCompany } from "../../../store/dashboardSlice";
import { cleanPastOrders, fetchPastOrders } from "../../../store/pastOrdersSlice";
import useLogout from "../../../useHooks/useLogout";
import SectionWrapper from "../../../wrapper/SectionWrapper"
import CompanyInfo from "../../companyInfo/CompanyInfo";
import PastOrderTable from "../../sharedComponent/PastOrderTable";
import { FunctionContext } from "../../UnderRoot";
import './PastOrders.scss';
import ShowOrderModal from "./ShowOrderModal";

const PastOrders = () => {
    const logout = useLogout()

    const dispatch = useDispatch()
    const { 
        companies, 
        selectedCompany, 
    } = useSelector( state => state.dashboard?.value )

    // const getCompanies = async () => {
    //     await dispatch( fetchCompanies() )
    // }

    // useEffect( () => {
    //     // initial fetch for companies when selectedCompany is empty
    //     if ( companies.length && !selectedCompany?.id ) dispatch( loadSelectedCompany( { company_id: companies[0].id } ) ) 
    // }, [ companies ] )

    // useEffect( () => {
    //     if ( !companies.length ) getCompanies()
    // }, [ companies ] )

    const companyChangeHandler = id => {
        dispatch( loadSelectedCompany( { company_id: Number( id ) } ) )
    }

    const {
        orders,
        current_page,
        rowsPerPage,
        ordersTotal
    } = useSelector( state => state.pastOrders.value )

    const getOrders = async ( page=1, per_page=10 ) => {
        const status = await dispatch( fetchPastOrders( page, per_page ) )

        if ( "logout" in status ) logout()
    }

    useEffect( () => {
        getOrders()

        return () => dispatch( cleanPastOrders() )
    }, [] )

    const pageChangeHandler = val => {
        getOrders( val + 1 )


    }
    // const { isMobile } = useContext( DataContext )
    const { modalOpen } = useContext( FunctionContext );

    const [ order, setOrder ] = useState( {} )
    const [ showOrderOpen, setShowOrder ] = useState( false )

    const rowHandler = ( { id } ) => {
        if ( !orders.length ) return

        const _order = orders.find( eachOrder => eachOrder.id === id )

        if ( !_order ) return
        setOrder( _order )
    }


    useEffect( () => {
        if ( order?.id ) setShowOrder( true )
        else setShowOrder( false )
    }, [ order ] )

    useEffect( () => {
        modalOpen( showOrderOpen )
    }, [ showOrderOpen ] )

    return (
        <SectionWrapper sectionClass={"past-orders"}>
            <CompanyInfo 
                companies={ companies }
                selectedCompany={ selectedCompany || "" }
                changeHandler={ companyChangeHandler }
                resetHandler={ getOrders } />
            <div className="last-orders-container">
                <p className="last-orders-legend">
                    My Past Orders
                </p>
                <PastOrderTable 
                    orders={ orders } 
                    current_page={ current_page - 1 } 
                    rowsPerPage={ rowsPerPage }
                    ordersTotal={ ordersTotal + 10 }
                    pageChangeHandler={ pageChangeHandler }
                    paginationActive={ true }
                    rowClickable={ true }
                    rowHandler={ rowHandler }
                    rowHandlerData={ ["id"] }
                    companies={ companies }
                    showCompany={ true } />
            </div>
            <Modal className="modal-parent" sx={ {} } open={ showOrderOpen }>
                <Box className="modal-box" sx={ { maxWidth: "500px", width: "calc(100vw - 2rem)", borderRadius: "2rem" } }>
                    <ShowOrderModal
                        order={ order }
                        cancelHandler={ () => setOrder( {} ) }/>
                </Box>
            </Modal>
        </SectionWrapper>
    )
}

export default PastOrders;