import { useState } from "react";
import { getHeader } from "../shared/Authorization";
import axios from "axios";
import { toast } from "react-toastify";

const SCRAPER_URL = process.env.REACT_APP_SCRAPER_API_URL;

export const useScrapeReviews = () => {
  const [isFetchingReviews, setIsFetchingReviews] = useState(false);
  const [scrapedReviews, setScrapedReviews] = useState([]);

  const fetchScrapedReviews = async (payload) => {
    try {
      setIsFetchingReviews(true);

      const response = await axios.post(
        SCRAPER_URL + "/fetch-reviewers",
        payload,
        {
          headers: getHeader(),
        }
      );

      setScrapedReviews(response.data.reviewers);
    } catch (err) {
      toast.err(err.response?.data?.message);
    } finally {
      setIsFetchingReviews(false);
    }
  };

  return {
    scrapedReviews,
    setScrapedReviews,
    fetchScrapedReviews,
    isFetchingReviews,
  };
};
