/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanMessages, fetchMessages } from "../../../store/messagesSlice";
import useLogout from "../../../useHooks/useLogout";
import MessageInboxTable from "./MessageInboxTable";

const MessageInbox = () => {
    const dispatch = useDispatch()
    const logout = useLogout()
    
    const user = useSelector( state => state.user.value )
    const messages = useSelector( state => state.messages.value.received )

    const getMessages = async () => {
        const status = dispatch( fetchMessages() )

        if ( "logout" in status ) logout()
    }

    useEffect( () => {
        getMessages()
        return () => {
            cleanMessages()
            dispatch( fetchMessages() )
        }
    }, [] )

    return (
        <MessageInboxTable messages={ messages } userId={ user?.id || 0 } />
    )
}

export default MessageInbox;