/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Slider, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanReviews,
  fetchReviews,
  loadSelectedCompany,
} from "../../../store/dashboardSlice";
import SectionWrapper from "../../../wrapper/SectionWrapper";
import CompanyInfo from "../../companyInfo/CompanyInfo";
import DataCard from "../../sharedComponent/DataCard";
import english_language from "../../../shared/english_language.json";
import german_language from "../../../shared/german_language.json";
import "./ReviewsCalculator.scss";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

const ReviewsCalculator = () => {
  const { companies, selectedCompany, ratings, reviews } = useSelector(
    (state) => state.dashboard?.value
  );
  const user = useSelector((state) => state.user.value);

  const { reviews_datacard_label, enter_target_rating_label } =
    user?.language === 2 ? english_language : german_language;

  const dispatch = useDispatch();

  const loadReviews = async () => {
    if (selectedCompany?.check_url === 1 && selectedCompany?.valid_url === 0) {
      toast.error("The URL is invalid.");
      return;
    }

    const status = await dispatch(fetchReviews(selectedCompany.id));

    if (!status?.ok) toast.error(status.message);
  };

  const companyChangeHandler = (id) => {
    dispatch(loadSelectedCompany({ company_id: Number(id) }));
  };
  // const [ company_id, setCompanyId ] = useState( null )

  useEffect(() => {
    if (selectedCompany?.id) loadReviews();
    return () => {
      dispatch(cleanReviews());
    };
  }, [selectedCompany]);

  const [currentFiveStar, setCurrentFiveStar] = useState(0);
  const [currentFourStar, setCurrentFourStar] = useState(0);
  const [currentThreeStar, setCurrentThreeStar] = useState(0);
  const [currentTwoStar, setCurrentTwoStar] = useState(0);
  const [currentOneStar, setCurrentOneStar] = useState(0);

  const resetHandler = () => {
    setTargetRating(0);
    setCurrentFiveStar(reviews?.five_star || 0);
    setCurrentFourStar(reviews?.four_star || 0);
    setCurrentThreeStar(reviews?.three_star || 0);
    setCurrentTwoStar(reviews?.two_star || 0);
    setCurrentOneStar(reviews?.one_star || 0);
  };

  useEffect(() => {
    resetHandler();
  }, [reviews]);

  const [target_rating, setTargetRating] = useState(1);

  const calculateRating = (
    _fiveStar,
    _fourStar,
    _threeStar,
    _twoStar,
    _oneStar
  ) => {
    const totalRatingNumber =
      _fiveStar + _fourStar + _threeStar + _twoStar + _oneStar;
    const totalRating =
      _fiveStar * 5 +
      _fourStar * 4 +
      _threeStar * 3 +
      _twoStar * 2 +
      _oneStar * 1;

    if (totalRatingNumber === 0) return 0;

    return (totalRating / totalRatingNumber).toFixed(2) || 0;
  };

  const calculatedRating = useMemo(() => {
    // const totalRatingNumber = currentFiveStar + currentFourStar + currentThreeStar + currentTwoStar + currentOneStar
    // const totalRating = ( currentFiveStar * 5 ) + ( currentFourStar * 4 ) + ( currentThreeStar * 3 ) + ( currentTwoStar * 2 ) + ( currentOneStar * 1 )

    // return ( totalRating / totalRatingNumber ).toFixed( 2 ) || 0

    return calculateRating(
      currentFiveStar,
      currentFourStar,
      currentThreeStar,
      currentTwoStar,
      currentOneStar
    );
  }, [
    currentFiveStar,
    currentFourStar,
    currentThreeStar,
    currentTwoStar,
    currentOneStar,
  ]);

  const calculateHandler = () => {
    if (target_rating <= calculatedRating) {
      toast.error("Target rating must be greater than current raating.");
      return;
    }

    let _currentFiveStar = currentFiveStar;
    let _currentFourStar = currentFourStar;
    let _currentThreeStar = currentThreeStar;
    let _currentTwoStar = currentTwoStar;
    let _currentOneStar = currentOneStar;

    let flow = true;

    do {
      const _calculation = calculateRating(
        _currentFiveStar,
        _currentFourStar,
        _currentThreeStar,
        _currentTwoStar,
        _currentOneStar
      );
      if (_calculation >= target_rating) break;

      if (_currentOneStar > 0) {
        _currentOneStar -= _currentOneStar;
        continue;
      }
      if (_currentTwoStar > 0) {
        _currentTwoStar -= _currentTwoStar;
        continue;
      }
      if (_currentThreeStar > 0) {
        _currentThreeStar -= _currentThreeStar;
        continue;
      }
      if (_currentFourStar > 0) {
        _currentFourStar -= _currentFourStar;
        continue;
      }
      if (_currentFiveStar > 0) {
        _currentFiveStar -= _currentFiveStar;
        continue;
      }
    } while (flow);
    setCurrentFiveStar(_currentFiveStar);
    setCurrentFourStar(_currentFourStar);
    setCurrentThreeStar(_currentThreeStar);
    setCurrentTwoStar(_currentTwoStar);
    setCurrentOneStar(_currentOneStar);
  };

  const totalDeletedReviews = useMemo(() => {
    return (
      (reviews?.four_star || 0) -
      currentFourStar +
      (reviews?.three_star || 0) -
      currentThreeStar +
      (reviews?.two_star || 0) -
      currentTwoStar +
      (reviews?.one_star || 0) -
      currentOneStar
    );
  });

  return (
    <SectionWrapper sectionClass={"reviews-calculator"}>
      <CompanyInfo
        companies={companies}
        selectedCompany={selectedCompany || ""}
        changeHandler={companyChangeHandler}
        sideClass={"-calculator-side"}
      >
        {/* {
                        applySpinner ?
                        <FidgetSpinner style={ { paddingLeft: "2rem", paddingRight: "2rem" } } className='-btn -gradient' /> :
                        ( 
                            user?.hasApplied ? <></> :
                            <Button 
                                variant="contained" 
                                sx={ { paddingLeft: "2rem", paddingRight: "2rem" } } 
                                className="-gradient"
                                onClick={ applyHandler }>
                                    Upgrade to VIP
                            </Button> )
                    } */}
      </CompanyInfo>
      <ul className="data-card-list reviews-calculator fd">
        <li>
          <div className="target-rating-container fd">
            <TextField
              id="target_rating"
              type="number"
              variant="standard"
              fullWidth
              label={enter_target_rating_label}
              value={target_rating}
              onChange={(e) => setTargetRating(e.target.value)}
              inputProps={{
                min: "1",
                max: "5",
                step: ".01",
              }}
            />
            <div className="base-btns fd">
              <Button
                variant="contained"
                fullWidth
                className="-dark"
                onClick={resetHandler}
              >
                RESET
              </Button>
              <Button
                variant="contained"
                fullWidth
                className="-gradient"
                onClick={calculateHandler}
              >
                CALCULATE
              </Button>
            </div>
          </div>
        </li>
        <li>
          <DataCard
            label={
              reviews_datacard_label.current_rating_label || "Current Rating"
            }
            value={ratings?.rating || 0}
            color="#48D16F"
          />
        </li>
        <li>
          <DataCard
            label={
              reviews_datacard_label.calculated_rating_label ||
              "Calculated Rating"
            }
            value={calculatedRating}
            color="#48D16F"
          />
        </li>
      </ul>
      <ul className="slider-container">
        <li>
          <p>{currentFiveStar} x 5 Star</p>
          <Slider
            valueLabelDisplay="auto"
            step={1}
            max={reviews?.five_star || 0}
            value={currentFiveStar}
            onChange={(e) => setCurrentFiveStar(e.target.value)}
          />
        </li>
        <li>
          <p>{currentFourStar} x 4 Star</p>
          <Slider
            valueLabelDisplay="auto"
            step={1}
            max={reviews?.four_star || 0}
            value={currentFourStar}
            onChange={(e) => setCurrentFourStar(e.target.value)}
          />
        </li>
        <li>
          <p>{currentThreeStar} x 3 Star</p>
          <Slider
            valueLabelDisplay="auto"
            step={1}
            max={reviews?.three_star || 0}
            value={currentThreeStar}
            onChange={(e) => setCurrentThreeStar(e.target.value)}
          />
        </li>
        <li>
          <p>{currentTwoStar} x 2 Star</p>
          <Slider
            valueLabelDisplay="auto"
            step={1}
            max={reviews?.two_star || 0}
            value={currentTwoStar}
            onChange={(e) => setCurrentTwoStar(e.target.value)}
          />
        </li>
        <li>
          <p>{currentOneStar} x 1 Star</p>
          <Slider
            valueLabelDisplay="auto"
            step={1}
            max={reviews?.one_star || 0}
            value={currentOneStar}
            onChange={(e) => setCurrentOneStar(e.target.value)}
          />
        </li>
      </ul>
      <div className="estimated-container fd">
        <div>
          <p className="title">Estimated Number of Reviews Needed to Delete</p>
          <ul className="star-reviews-list">
            <li>
              {(reviews?.four_star || 0) - currentFourStar} x 4 star Reviews
            </li>
            <li>
              {(reviews?.three_star || 0) - currentThreeStar} x 3 star Reviews
            </li>
            <li>
              {(reviews?.two_star || 0) - currentTwoStar} x 2 star Reviews
            </li>
            <li>
              {(reviews?.one_star || 0) - currentOneStar} x 1 star Reviews
            </li>
          </ul>
        </div>
        <div>
          <p className="title">Total Estimated Reviews to Delete</p>
          <p>{totalDeletedReviews} reviews</p>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default ReviewsCalculator;
