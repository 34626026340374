/* eslint-disable react-hooks/exhaustive-deps */
// import { Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, MenuItem, Modal, Select } from "@mui/material"
import { Button, Dialog, FormControl, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addOrder } from "../../store/dashboardSlice";
import { FunctionContext } from "../UnderRoot";
import AddOrderModal from "./AddOrderModal";
import "./CompanyInfo.scss";
import english_language from "../../shared/english_language.json";
import german_language from "../../shared/german_language.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from "react-router-dom";
// import { applyTier, fetchUser } from "../../store/userSlice";
// import FidgetSpinner from "../sharedComponent/FidgetSpinner";

const CompanyInfo = ({
  children = null,
  companies = [],
  selectedCompany = { id: "" },
  changeHandler = () => "",
  resetHandler = () => {},
  sideClass = "",
  sideStyle = {},
  addOrderOpen = false,
  setAddOpen,
  hasMemberButton = true,
}) => {
  const user = useSelector((state) => state.user.value);
  const { modalOpen } = useContext(FunctionContext);

  const {
    company_info_label,
    // apply_dialog_label,
    business_invalid_label,
  } = user?.language === 2 ? english_language : german_language;

  const dispatch = useDispatch();

  useEffect(() => {
    modalOpen(addOrderOpen);
  }, [addOrderOpen]);

  const saveHandler = async (data) => {
    if (!selectedCompany.id) {
      console.log("company_id is empty");
      return;
    }
    if (!data.order_reviews.length) {
      toast.error("Reviews cannot be empty");
      return;
    }

    const formData = new FormData();

    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    formData.append("company_id", selectedCompany.id);

    const status = await dispatch(addOrder(formData));

    if (status.ok) {
      resetHandler();
    }

    setAddOpen(false);
  };

  // const [ dialogOpen, setDialogOpen ] = useState( false )

  // const handleClose = () => {
  //     setDialogOpen( false )
  //     dispatch( fetchUser() )
  // }

  // const [ applySpinner, setApplySpinner ] = useState( false )

  // const applyHandler = async () => {

  //     setApplySpinner( true )

  //     const status = await dispatch( applyTier( 2 ) )

  //     if ( !status.ok ) toast.error( status.message )
  //      else setDialogOpen( true )
  //     setApplySpinner( false )
  // }

  // const navigate = useNavigate()

  // const applyBtnHandler = () => {
  //     navigate("/membership")
  // }

  return (
    <>
      {selectedCompany?.valid_url === 0 && selectedCompany?.check_url === 1 && (
        <Link className="link-invalid" to={"/profile/business_information"}>
          {" "}
          {business_invalid_label}{" "}
        </Link>
      )}
      <div className="company-info-card fd">
        <div className="main fd">
          <div className="company-name">
            <p>{company_info_label?.company_name_label || "Company Name:"}</p>
            <FormControl>
              <Select
                id="company-select"
                variant="standard"
                value={selectedCompany?.id || ""}
                onChange={(e) => changeHandler(e.target.value)}
                sx={{ maxWidth: "15rem" }}
                defaultValue={""}
                MenuProps={{
                  style: {
                    maxWidth: "15rem",
                    width: "100%",
                    maxHeight: "15rem",
                  },
                }}
              >
                {companies.map((company, i) => (
                  <MenuItem key={i} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <p className="company-link">
            <span>
              {company_info_label?.business_link_label ||
                "Google My Business Link:"}
            </span>
            {selectedCompany.valid_url === 1 ? (
              <a
                className="-actual-link"
                href={selectedCompany.url}
                target="_blank"
                rel="noreferrer"
              >
                {selectedCompany.url || "None"}
                {/* <FontAwesomeIcon icon={solid("briefcase")} style={ { fontSize: "1.25rem" } } /> */}
              </a>
            ) : (
              <span className="-actual-link" title="Link is invalid">
                {selectedCompany.url || "None"}
                {/* <FontAwesomeIcon icon={solid("briefcase")} style={ { fontSize: "1.25rem" } } /> */}
              </span>
            )}
          </p>
        </div>
        <div className={`side fd ${sideClass}`} style={sideStyle}>
          <div>
            {/* <Dialog open={dialogOpen}>
                            <DialogContent>
                                <DialogContentText> */}
            {/* We will contact you directly to send you invoice. Thank you so much! */}
            {/* { apply_dialog_label } */}
            {/* </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={ handleClose }>Close</Button>
                            </DialogActions>
                        </Dialog> */}
            {/* {
                                hasMemberButton && !user?.hasApplied &&
                                <>
                                    <Button
                                        variant="contained" 
                                        sx={ { paddingLeft: "1.5rem", paddingRight: "1.5rem" } } 
                                        className="-gradient"
                                        onClick={ applyBtnHandler }>
                                            { company_info_label.upgrade_btn_label }
                                    </Button>
                                    {
                                        // applySpinner ?
                                        // <FidgetSpinner style={ { minWidth: "10rem" } } className='-btn -gradient' /> :
                                        // ( 
                                        //     user?.hasApplied ? <></> :
                                        //     <Button 
                                        //         variant="contained" 
                                        //         sx={ { paddingLeft: "1.5rem", paddingRight: "1.5rem" } } 
                                        //         className="-gradient"
                                        //         onClick={ applyHandler }>
                                        //             { company_info_label.upgrade_btn_label }
                                        //     </Button> 
                                        // )
                                    }
                                </>
                        } */}
          </div>
          {
            <div className="seller-contacts fd">
              {user?.phone && (
                <a href={`tel:${user?.phone}`} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={solid("phone")} />
                </a>
              )}
              {user?.contact_url && (
                <a href={user?.contact_url} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={solid("calendar")} />
                </a>
              )}
            </div>
          }
          <Button
            className="-dark"
            variant="contained"
            sx={{ padding: "0 1.5rem" }}
            onClick={() => setAddOpen(!addOrderOpen)}
          >
            {company_info_label?.new_order_label || "New Order"}
          </Button>
        </div>
      </div>
      <Dialog
        // className="modal-parent"
        open={addOrderOpen}
        onClose={() => setAddOpen(false)}
      >
        <Box
          className="modal-box"
          sx={{
            maxWidth: "500px",
            width: "calc(100vw - 2rem)",
            borderRadius: "2rem",
          }}
        >
          <AddOrderModal
            cancelHandler={() => setAddOpen(false)}
            saveHandler={saveHandler}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default CompanyInfo;
