import english_language from '../../../shared/english_language.json';
import german_language from '../../../shared/german_language.json';
import { Box, Tab, Tabs } from "@mui/material"
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const MessageNav = ( ) => {
    const user = useSelector( state => state.user.value )
    const { pathname } = useLocation()
    const { messages_label } = user?.language === 2 ? english_language : german_language

    const navigate = useNavigate()

    return (
        <Box className="tabBox message-nav" sx={{ width: "100%", maxWidth: "25rem", borderBottom: 1, borderColor: '#C5C5C5', marginBottom: "2rem" }}>
            <Tabs
                allowScrollButtonsMobile 
                TabIndicatorProps={
                    {
                        style: {
                            background: "#48D16F"
                        }
                    }
                } value={ pathname || "" }>
                <Tab 
                    value="/message" 
                    label={ messages_label?.nav?.[0] || "My Inbox" }
                    sx={ { color: "#C5C5C5",  letterSpacing: '0!important', fontSize: '1rem!important'  } }  
                    onClick={ () => navigate("/message") } >
                </Tab>
                <Tab 
                    value="/message/sent"
                    label={ messages_label?.nav?.[1] || "Sent Messages" }
                    sx={ { color: "#C5C5C5",  letterSpacing: '0!important', fontSize: '1rem!important'  } }  
                    onClick={ () => navigate("/message/sent") } >
                </Tab>
            </Tabs>
        </Box>
    )
}

export default MessageNav;