import React, { useEffect } from "react";
import SectionWrapper from "../../../wrapper/SectionWrapper";
import CompanyInfo from "../../companyInfo/CompanyInfo";
import { useDispatch, useSelector } from "react-redux";
import { loadSelectedCompany } from "../../../store/dashboardSlice";
import useLogout from "../../../useHooks/useLogout";
import {
  cleanActiveOrders,
  fetchActiveOrders,
} from "../../../store/activeOrdersSlice";
import ActiveOrderCards from "./ActiveOrderCards";
import { Box } from "@mui/material";

const ActiveOrders = () => {
  const logout = useLogout();
  const dispatch = useDispatch();

  const { companies, selectedCompany } = useSelector(
    (state) => state.dashboard?.value
  );

  const { orders } = useSelector((state) => state.activeOrders?.value);

  const getOrders = async () => {
    const status = await dispatch(fetchActiveOrders());

    if ("logout" in status) logout();
  };

  useEffect(() => {
    getOrders();

    return () => dispatch(cleanActiveOrders());
    //eslint-disable-next-line
  }, []);

  const companyChangeHandler = (id) => {
    dispatch(loadSelectedCompany({ company_id: Number(id) }));
  };

  return (
    <SectionWrapper sectionClass={"past-orders"}>
      <CompanyInfo
        companies={companies}
        selectedCompany={selectedCompany || ""}
        changeHandler={companyChangeHandler}
        resetHandler={getOrders}
      />

      {orders?.length > 0 && (
        <Box sx={{ mt: "4rem" }}>
          <ActiveOrderCards orders={orders} />
        </Box>
      )}
    </SectionWrapper>
  );
};

export default ActiveOrders;
