/* eslint-disable react-hooks/exhaustive-deps */
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Routes,
  Route,
  useLocation,
  matchPath,
  useNavigate,
} from "react-router-dom";
import Main from "./main/Main";
import LandPage from "./main/landpage/LandPage";
import Dashboard from "./main/dashboard/Dashboard";
import { createContext, useEffect, useMemo, useState } from "react";
import Header from "./header/Header";
import "./UnderRoot.scss";
import PastOrders from "./main/pastOrders/PastOrders";
import { checkAuthorization } from "../shared/Authorization";
import Profile from "./main/profile/Profile";
import {
  fetchUser,
  updatePersonalInformation,
  updateUser,
} from "../store/userSlice";
import { fetchCompanies, loadSelectedCompany } from "../store/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import Message from "./main/messages/Message";
import Login from "./main/landpage/Login";
import ForgetPassword from "./main/landpage/ForgetPassword";
import useLogout from "../useHooks/useLogout";
import {
  storageLoginLanguageGet,
  storageLoginLanguageSet,
} from "../shared/CustomFunc";
import ReviewsCalculator from "./main/reviewsCalculator/ReviewsCalculator";
import AdminLogin from "./main/landpage/AdminLogin";
import { removeAuth } from "../store";
import ActiveOrders from "./main/activeOrders/ActiveOrders";
// import Membership from './main/membership/Membership';

const PORTAL_PATHS = [
  "/",
  // "/membership",
  "/dashboard",
  "/calculator",
  "/active-orders",
  "/past-orders",
  "/profile",
  "/profile/personal_information",
  "/profile/business_information",
  "/profile/change_password",
  "/profile/language",
  "/message/",
  "/message/sent",
  "/message/read",
  // 11 index
  "/forget-password/",
  "/adminlogin/",
];

const DataContext = createContext(null);
const FunctionContext = createContext(null);

const UnderRoot = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // contexts

  const modalOpen = (open) => {
    const root = document.querySelector("#root");
    const body = document.querySelector("body");

    if (open && root.position !== "fixed") {
      root.style.position = "fixed";
      if (!body?.className?.includes("-modal-open"))
        body.classList.toggle("-modal-open");
    } else if (!open && root.position !== "static") {
      root.style.position = "static";
      if (body?.className?.includes("-modal-open"))
        body.classList.toggle("-modal-open");
    }
  };

  const [isMobile, setIsMobile] = useState(false);
  const [isTrueMobile, setIsTrueMobile] = useState(false);

  const resizeObserver = new ResizeObserver((entry) => {
    const viewWidth = entry[0].contentRect.width;

    if (viewWidth > 1024) {
      setIsMobile(false);
      setIsTrueMobile(false);
    } else {
      setIsMobile(true);
      if (viewWidth < 767) setIsTrueMobile(true);
      else setIsTrueMobile(false);
    }
  });

  useEffect(() => {
    const body = document.querySelector("body");

    resizeObserver.observe(body);
    return () => resizeObserver.disconnect();
  }, []);

  // contexts

  useEffect(() => {
    const isAuthorized = checkAuthorization();
    const matchLandPage = matchPath("/", pathname);

    const matchLogOut = matchLandPage
      ? matchLandPage
      : pathname.includes("/forget-password/") ||
        pathname.includes("/adminlogin/")
      ? true
      : false;

    // if we are not matching landpage
    // it means we are supposed to be logged
    // so we check if we are authorized
    // otherwise, we can't go to our land page if we are already logged

    // if ( !matchLandPage ) {
    //     if ( !isAuthorized ) navigate("/")
    // }

    if (!matchLogOut) {
      // if we are NOT matching log out pages
      // landpage, forget password
      // and we are not authorized we get out
      if (!pathname.includes(PORTAL_PATHS[11]))
        if (!isAuthorized) navigate("/");
    } else {
      if (isAuthorized) navigate("/dashboard");
    }
  }, [pathname]);

  const isMatched = useMemo(() => {
    // checks if the path matches any of our path
    // if it doesn't we go back
    // this is just here to hide our nav inbetween paths that doesn't exist
    return PORTAL_PATHS.some((path) => {
      const _isMatched = matchPath(path, pathname);

      if (path === "/message/read") {
        if (pathname.includes(path)) return true;
      }
      if (path === "/forget-password/") {
        if (pathname.includes(path)) return true;
      }
      if (path === "/adminlogin/") {
        if (pathname.includes(path)) return true;
      }

      if (!_isMatched) return false;
      else return true;
    });
  }, [pathname]);

  const underrootLocationClass = useMemo(() => {
    // this is just an indication whether to hide or show our nav
    const matchLandPage = matchPath("/", pathname);

    const matchLogOut = matchLandPage
      ? matchLandPage
      : pathname.includes("/forget-password/") ||
        pathname.includes("/adminlogin/")
      ? true
      : false;

    // if matching non log in page
    if (!matchLogOut) return "-nav-show";

    return "-nav-hide";
  }, [pathname]);

  useEffect(() => {
    // if no path is matched we navigate to our landpage
    if (!isMatched) navigate("/");
  }, [isMatched]);

  const user = useSelector((state) => state.user.value);

  const logout = useLogout();

  const getUser = async () => {
    const status = await dispatch(fetchUser());
    if ("logout" in status) logout();
  };

  useEffect(() => {
    const isAuthorized = checkAuthorization();

    if (isAuthorized && !user?.id) getUser();
  }, [pathname]);

  const [currentUserId, setCurrentUserId] = useState(null);

  const { companies, selectedCompany } = useSelector(
    (state) => state.dashboard?.value
  );

  useEffect(() => {
    // initial fetch for companies when selectedCompany is empty
    if (companies.length && !selectedCompany?.id)
      dispatch(loadSelectedCompany({ company_id: companies[0].id }));
  }, [companies]);

  const getCompanies = async () => {
    const status = await dispatch(fetchCompanies());
    if ("logout" in status) logout();
  };

  const setLanguage = () => {
    const whichLanguage = storageLoginLanguageGet();

    if (!whichLanguage) storageLoginLanguageSet(user.language);
    else {
      const localLanguage = Number(whichLanguage);

      const data = {
        name: user.name,
        email: user.email,
        language: localLanguage,
      };

      if (user?.language !== localLanguage) {
        dispatch(updatePersonalInformation(data));
        dispatch(updateUser(data));
      }
    }
  };

  useEffect(() => {
    const isAuthorized = checkAuthorization();

    if (isAuthorized && user?.id) {
      if (user.id === currentUserId) return;

      setLanguage();

      setCurrentUserId(user.id);
      getCompanies();
    } else {
      if (currentUserId) setCurrentUserId(null);
    }
  }, [user]);

  return (
    <DataContext.Provider
      value={{
        isMobile,
        isTrueMobile,
      }}
    >
      <FunctionContext.Provider
        value={{
          modalOpen,
        }}
      >
        <ToastContainer
          position="bottom-right"
          autoClose={1500}
          closeOnClick
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          hideProgressBar
          rtl={false}
        />
        <div className={`underroot ${underrootLocationClass}`}>
          {underrootLocationClass === "-nav-show" && isMatched && <Header />}
          <Routes>
            <Route path="/*" element={<Main />}>
              <Route
                index
                element={
                  <LandPage>
                    <Login />
                  </LandPage>
                }
              />
              <Route
                path="forget-password/:code"
                element={
                  <LandPage>
                    <ForgetPassword />
                  </LandPage>
                }
              />
              <Route
                path="adminlogin/:encodedEmail"
                element={
                  <LandPage>
                    <AdminLogin />
                  </LandPage>
                }
                loader={async () => {
                  return removeAuth();
                }}
              />
              {/* <Route path='membership' element={ <Membership /> } /> */}
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="calculator" element={<ReviewsCalculator />} />
              <Route path="active-orders" element={<ActiveOrders />} />
              <Route path="past-orders" element={<PastOrders />} />
              <Route path="profile/*" element={<Profile />} />
              <Route path="message/*" element={<Message />} />
              <Route path="*" element={<p>Nothing here...</p>} />
            </Route>
          </Routes>
        </div>
      </FunctionContext.Provider>
    </DataContext.Provider>
  );
};

export default UnderRoot;
export { FunctionContext, DataContext };
