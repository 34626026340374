/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import { storageLoginLanguageGet, storageLoginLanguageSet } from "../../../shared/CustomFunc";
// import { Route, Routes } from "react-router-dom";
import SectionWrapper from "../../../wrapper/SectionWrapper";
import './LandPage.scss';
// import Login from "./Login";
// import english_language from '../../../shared/english_language.json';
// import german_language from '../../../shared/german_language.json';
    // import { useSelector } from "react-redux";

const LandPageContext = createContext( null )

const LandPage = ( { children } ) => {
    // 1 - german
    // 2 - english
    const [ whichLanguage, setWhichLanguage ] = useState( 1 )
    
    useEffect( () => {
        const _whichLanguage = storageLoginLanguageGet()

        if ( !_whichLanguage ) {
            setWhichLanguage( whichLanguage )
            storageLoginLanguageSet( whichLanguage )
        } else {
            const numWhichLanguage = Number( _whichLanguage )
        
            if ( numWhichLanguage !== whichLanguage ) setWhichLanguage( numWhichLanguage )
        }
    
        return () => {
            const _whichLanguage_newest = storageLoginLanguageGet()

            if ( !_whichLanguage_newest ) storageLoginLanguageSet( whichLanguage )
        }
    }, [] )

    const changeLanguageHandler = () => {
        const newWhichLanguage = whichLanguage === 1 ? 2 : 1

        storageLoginLanguageSet( newWhichLanguage )
        setWhichLanguage( newWhichLanguage )

    }

    return (
        <SectionWrapper sectionClass={"landpage fd"}>
            <LandPageContext.Provider value={ { whichLanguage } }>
                <Box className="landpage-box" >
                    <img src={ whichLanguage === 1 ? '/landpage-icon-ger.png' : '/landpage-icon-eng.png' } alt="icon for customer portal"/>
                    {
                        children
                    }
                </Box>
                <div className="language-btn fd" onClick={changeLanguageHandler}>
                    <img src={ whichLanguage === 1 ? '/germanflag.webp' : '/englishflag.webp' } alt="flag" />
                </div>
            </LandPageContext.Provider>
        </SectionWrapper>
    )
}

export default LandPage;
export { LandPageContext };