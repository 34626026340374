import { Box } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import { getStatusName } from "../../../utils/utils";
dayjs.extend(utc);
dayjs.extend(relativeTime);

const ActiveOrderCards = ({ orders = [] }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "nowrap",
        gap: "1rem",
        width: "100%",
      }}
    >
      {orders.map((order, index) => {
        return (
          <Box
            key={index}
            sx={{
              width: "100%",
              maxWidth: { md: "25rem" },
              borderRadius: "1rem",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important",
              border: "1px solid rgb(229 231 235)",
            }}
          >
            <Box
              sx={{
                p: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                borderBottom: "1px solid rgb(229 231 235)",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: "1fr auto",
                }}
              >
                <Box>
                  <span>Order #</span>
                  <span>{order.id}</span>
                </Box>
                <Box>
                  <span>{dayjs(order.updated_at).local().fromNow()}</span>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: "1fr auto",
                }}
              >
                <span>Unit Price</span>
                <span>€{order.unit_cost}/review</span>
              </Box>
            </Box>

            {order.order_reviews?.map((review, rIndex) => {
              return (
                <Box
                  key={rIndex}
                  sx={{ p: "1rem", borderBottom: "1px solid rgb(229 231 235)" }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gap: "1rem",
                      gridTemplateColumns: "1fr auto",
                    }}
                  >
                    <Box>
                      <span>Review of </span>
                      <span>{review.name}</span>
                    </Box>
                    <Box
                      sx={{
                        px: "1rem",
                        backgroundColor: "#48D16F",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ color: "white", fontSize: "0.85rem" }}>
                        {getStatusName(review.status)}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })}

            <Box
              sx={{
                display: "grid",
                gap: "1rem",
                p: "1rem",
                gridTemplateColumns: "1fr auto",
              }}
            >
              <span>Total Price</span>
              <span>€{order.total_price}</span>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ActiveOrderCards;
