/* eslint-disable no-useless-computed-key */
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getHeader } from '../shared/Authorization';
import { ErrorHandler } from "../shared/CustomFunc";

const ROOT_URL = process.env.REACT_APP_API_URL

export const messagesSlice = createSlice( {
    name: 'messages',
    initialState: {
        value: {
            received: [],
            sent: [],
            reading: {}
        }
    },
    reducers: {
        loadMessages: ( state, action ) => {
            state.value.received = action.payload.messages
        },
        loadSentMessages: ( state, action ) => {
            state.value.sent = action.payload.messages
        },
        loadReadingMessage: ( state, action ) => {
            state.value.reading = action.payload.message
        },
        cleanMessages: state => {
            state.value.received = []
        },
        cleanSentMessages: state => {
            state.value.sent = []
        },
        cleanReadingMessage: ( state ) => {
            state.value.reading = {}
        }
    }
} )

export const { 
    loadMessages, 
    loadSentMessages, 
    loadReadingMessage, 
    cleanMessages, 
    cleanSentMessages,
    cleanReadingMessage
} = messagesSlice.actions

export const fetchMessages = () => async dispatch => {
    const headers = getHeader();

    return await axios.get( ROOT_URL + '/message/inbox', { headers } )
    .then( resp => {
        dispatch( loadMessages( resp.data ) )
        return { ok: true }
    } )
    .catch( err => ErrorHandler( err, toast, "Error fetching messages" ) )
}

export const fetchSentMessages = () => async dispatch => {
    const headers = getHeader();

    return await axios.get( ROOT_URL + '/message/sent', { headers } )
    .then( resp => {
        dispatch( loadSentMessages( resp.data ) )
        return { ok: true }
    } )
    .catch( err => ErrorHandler( err, toast, "Error fetching messages" ) )
}

export const fetchReadingMessageSent = id => async dispatch => {
    const  headers = getHeader();

    return await axios.get( ROOT_URL + '/message/inbox/' + id, { headers } )
    .then( resp => {
        dispatch( loadReadingMessage( resp.data ) )
        return { ok: true }
    } )
    .catch( err => ErrorHandler( err, toast, "Error fetching message" ) )
}

export const fetchReadingMessageInbox = id => async dispatch => {
    const headers = getHeader( { 'Content-Type': 'application/x-www-form-urlencoded' } );

    return await axios.put( ROOT_URL + '/message/inbox/' + id, {}, { headers } )
    .then( resp => {
        dispatch( loadReadingMessage( resp.data ) )
        return { ok: true }
    } )
    .catch( err => ErrorHandler( err, toast, "Error fetching message" ) )
}

export const sendMessage = data => async () => {
    const headers = getHeader({ 'Content-Type': 'multipart/form-data' } )

    return await axios.post( ROOT_URL + '/message/send-message', data, { headers } )
    .then( () => { return { ok: true, message: "Message sent" } } )
    .catch( err => ErrorHandler( err, toast, "Error sending message" ) )
}

export default messagesSlice.reducer