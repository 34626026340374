import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import dashboardReducer from "./dashboardSlice";
import pastOrdersReducer from "./pastOrdersSlice";
import messageReducer from "./messagesSlice";
import activeOrdersReducer from "./activeOrdersSlice";

const rootReducer = {
  user: userReducer,
  dashboard: dashboardReducer,
  activeOrders: activeOrdersReducer,
  pastOrders: pastOrdersReducer,
  messages: messageReducer,
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export const removeAuth = () => {
  localStorage.removeItem("grocket_customer_access");
};
